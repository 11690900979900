<template>
  <a-modal :visible="show"
           :title="flowType==1?'添加退款流水':'添加补差流水'"
           :afterClose="remove"
           width="550px"
           @cancel="remove"
           @ok="handleOk">
    <a-form-model ref="tempRef"
                  :labelCol="{ span: 4 }"
                  :wrapperCol="{ span: 20 }"
                  :model="addFrom">
      <a-form-model-item class="mb-2" prop="amount" :label="flowType==1?'退款金额':'补差金额'">
        <a-input-number class="mr-2" style="width:170px;" placeholder="金额"
            :precision="2" :min="0.1" 
            v-model="addFrom.amount" @change="onChangeNumber" /> 元
      </a-form-model-item>
      <a-form-model-item class="mb-2" prop="flow_time" :label="flowType==1?'退款时间':'补差时间'">
        <a-date-picker placeholder="流水真实发生的时间" 
            valueFormat="YYYY-MM-DD HH:mm:ss" show-time 
            v-model="addFrom.flow_time" @ok="onOkTime" />
      </a-form-model-item>
      <a-form-model-item class="mb-2" prop="remarks" label="备注">
        <a-input allowClear placeholder="请输入备注信息"
                 type="textarea"
                 v-model="addFrom.remarks"></a-input>
      </a-form-model-item>
      <div style="color:#999;font-size:12px;">
        <div>*系统会自动抓取第三方平台的支付/退款流水、不需要手动添加（如遇例外 反馈给技术）；</div>
        <div>*但如果通过非本订单（比如补差订单/红包）收款/退款、系统无法抓取、需要人工补录流水；</div>
      </div>
    </a-form-model>
  </a-modal>

</template>

// <script>
import moment from "moment"
import {
  // getCakeOrderList, //test
  otherOrderAddFlow,
} from "@/api/customer"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [Number,String],
      default: ''
    },
    flowType: {
      type: [Number,String],
      default: ''
    }
  },
  data () {
    return {
      addFrom: {
        amount: '',
        flow_time	: '',
        remarks: ''
      },
    }
  },

  methods: {
    moment,
    onChangeNumber(value) {
      console.log('changed', value)
    },
    onOkTime(value) {
      console.log('onOk: ', value)
    },
    remove () {
      this.$emit('update:show', false)
    },
    async handleOk () {
      if(!this.addFrom.amount){
        this.$message.warning('请填写退款金额')
        return
      }
      if(!this.addFrom.flow_time){
        this.$message.warning('请选择退款时间')
        return
      }
      if(!this.addFrom.remarks){
        this.$message.warning('请填写备注')
        return
      }
      let params = {
        other_order_id: this.orderId,
        flow_type: this.flowType,
        amount: this.addFrom.amount,
        flow_time: this.addFrom.flow_time,
        remarks: this.addFrom.remarks
      }
      //test
      let { code } = await otherOrderAddFlow(params)
      // let { code } = await getCakeOrderList(params)
      code = 0
      if(code == 0) {
        this.$message.success('成功！')
        this.$emit('update:show', false)
        this.$emit('addPrice', this.addFrom.amount)
      }

    },
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.coupon-price{
  margin-left: 10px;
  color: #ccc;
}
</style>