<template>
<div class="scroll-y-con">
  <div class="top-title" v-if="otherOrderData">
    <div>
      {{ formatOtherOrderSource(otherOrderData.other_order_source) }}订单ID: {{ otherOrderData.other_order_no }} 
      （{{ formatOtherOrderStatus(otherOrderData.other_order_status) }}
      <template v-if="otherOrderData.other_order_status <= 21">，{{isSended?"已发货":"未发货"}}</template>）
      <span class="ml-2">下单时间:{{ otherOrderData.other_order_add_time }}</span>     
    </div>
    <div>
      <a-button v-if="!isTakeawayPlat"
        type="link" class="p-0"
        @click="isShowExpressDetail=true"
      >原单退款/快递</a-button>
      <template v-if="hasAllAuth">
        <a-button 
          v-if="otherOrderData.other_order_status == 13"
          type="link" class="p-0 ml-4"
          @click="handlerContinue">继续处理</a-button>
        <a-button v-if="otherOrderData.other_order_status == 23"
          type="link" class="p-0 ml-4"
          @click="handlerRefundApply">添加售后记录</a-button>
        <a-button v-if="otherOrderData.other_order_status == 24"
          type="link" class="p-0 ml-4"
          @click="handlerRefundApply">重填售后记录</a-button>
        <a-button v-if="isCanCancel" style="color:red;"
          type="link" class="p-0 ml-4" @click="handlerCancel">取消订单</a-button>
      </template>
    </div>
  </div>
  <div v-if="otherOrderData">
    <div class="page-content thirdpartdiv">
      <div class="flex justify-between mt-3">
        <div class="flex-1">
          <!-- <div class="not-form">
            <div class="dflex" style="flex:2;">
              <span class="label">收货人:</span>
              <div class="val">
                <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_name']">
                  <a-input readOnly
                    :class="{ 'has-update': dataUpdateMap['other_order_consignee_name'] }"
                    v-model="otherOrderData.other_order_consignee_name"></a-input>
                </a-tooltip>
              </div>
            </div>
            <div class="dflex ml-4" style="flex:3;">
              <span class="label">手机号:</span>
              <div class="val">
                <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_phone']">
                  <a-input readOnly
                    :class="{ 'has-update': dataUpdateMap['other_order_consignee_phone'] }"
                    v-model="otherOrderData.other_order_consignee_phone"></a-input>
                </a-tooltip>
              </div>
            </div>
            <div class="dflex ml-4" style="flex:6;">
              <span class="label">收货地址:</span>
              <div class="val">
                <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_address']">
                  <a-input
                    :class="{ 'has-update': dataUpdateMap['other_order_consignee_address'] }"
                    v-model="otherOrderData.other_order_consignee_address"></a-input>
                </a-tooltip>
              </div>
            </div>
          </div> -->
          <div class="not-form">
            <span class="label">收货人:</span>
            <div class="val" style="flex:2;">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_name']">
                <a-input readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_consignee_name'] }"
                  v-model="otherOrderData.other_order_consignee_name"></a-input>
              </a-tooltip>
            </div>
            <span class="label ml-3">手机号:</span>
            <div class="val" style="flex:3;">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_phone']">
                <a-input readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_consignee_phone'] }"
                  v-model="otherOrderData.other_order_consignee_phone"></a-input>
              </a-tooltip>
            </div>
            <span class="label ml-4">下单人:</span>
            <div class="val" style="flex:2;">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_place_order_name']">
                <a-input readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_place_order_name'] }"
                  v-model="otherOrderData.other_order_place_order_name"></a-input>
              </a-tooltip>
            </div>
            <span class="label ml-3">手机号:</span>
            <div class="val" style="flex:3;">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_place_order_phone']">
                <a-input readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_place_order_phone'] }"
                  v-model="otherOrderData.other_order_place_order_phone"></a-input>
              </a-tooltip>
            </div>
          </div>
          <div class="not-form mt-3">
            <div class="dflex" style="flex:1;">
              <span class="label">收货地址:</span>
              <div class="val">
                <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_consignee_address']">
                  <a-input
                    :class="{ 'has-update': dataUpdateMap['other_order_consignee_address'] }"
                    v-model="otherOrderData.other_order_consignee_address"></a-input>
                </a-tooltip>
              </div>
            </div>
          </div>

          <div v-if="isTakeawayPlat" class="not-form mt-3">
            <span class="label">下单门店:</span>
            <div class="val">
              {{otherOrderData.outlets_name}}
            </div>
            <template v-if="otherOrderData.other_order_type == 1">
              <span class="label ml-8">自提时间:</span>
              <div class="val">
                {{otherOrderData.otherShowTime}}
              </div>
            </template>
            <template v-if="otherOrderData.other_order_type == 2">
              <span class="label ml-8">配送时间:</span>
              <div class="val">
                {{otherOrderData.otherShowTime}}
              </div>
            </template>
          </div>

          <div class="not-form mt-3">
            <span class="label" style="width:120px;">第三方下单备注:</span>
            <div class="val flex-1">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_remarks']">
                <a-textarea readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_remarks'] }"
                  v-model="otherOrderData.other_order_remarks"
                  :auto-size="{ minRows: 1, maxRows: 6 }" />
              </a-tooltip>
            </div>
          </div>
          <div class="not-form mt-3">
            <span class="label" style="width:120px;">第三方客服备注:</span>
            <div class="val flex-1">
              <a-tooltip placement="topLeft" :title="dataUpdateMap['other_order_customer_service_remarks']">
                <a-textarea readOnly
                  :class="{ 'has-update': dataUpdateMap['other_order_customer_service_remarks'] }"
                  v-model="otherOrderData.other_order_customer_service_remarks"
                  :auto-size="{ minRows: 1, maxRows: 6 }" />
              </a-tooltip>
            </div>
          </div>
        </div>

        <!-- 第三方订单 右侧金额区域 -->
        <!-- <div class="amount-pannel">
          <div class="flex justify-between grey">
            <span>商品总价:</span><span>{{ otherOrderData.other_order_goods_amount }} 元</span>
          </div>
          <div class="flex justify-between grey">
            <span>优惠:</span><span>{{ otherOrderData.discount_amount }} 元</span>
          </div>
          <div class="flex justify-between grey">
            <span>运费:</span><span>{{ otherOrderData.other_order_freight_amount }} 元</span>
          </div>
          <div class="flex justify-between font-bold mb-2">
            <span>第三方订单总价:</span><span>{{ otherOrderData.other_order_amount }} 元</span>
          </div>
          <div class="flex justify-between">
            <span>补差:</span><span>{{ otherOrderData.other_order_add_amount }} 元</span>
          </div>
          <div class="flex justify-between">
            <span>退款:</span><span>{{ Number(otherOrderData.other_order_refund_total) > 0 ? ("-"+otherOrderData.other_order_refund_total) : otherOrderData.other_order_refund_total }} 元</span>
          </div>
          <div class="flex justify-between items-center font-bold">
            <span>第三方流水总额:</span><span style="font-size:18px;">{{ otherTotalPrice }} 元</span>
          </div>
          <div v-if="hasAllAuth" class="flex justify-between mt-1">
            <div>
              <a-button class="p-0" size="small" type="link" @click="handlerAddFlow(2)">添加补差</a-button> /
              <a-button class="p-0" size="small" type="link" @click="handlerAddFlow(1)">添加退款</a-button>
            </div>
            <a-button class="p-0" size="small" type="link" @click="isShowFlowLog=true">流水明细</a-button>
          </div>
        </div> -->
        <div class="amount-pannel" style="margin-left:2%;">
          <div class="flex justify-between grey">
            <div class="flex justify-between">
              <span>商品总价:</span><span>{{ otherOrderData.other_order_goods_amount }} 元</span>
            </div>
            <div></div>
          </div>
          <div class="flex justify-between grey"
            v-if="Number(otherOrderData.other_order_offers_amount) != 0">
            <div class="flex justify-between">
              <span>优惠:</span><span>-{{ otherOrderData.other_order_offers_amount }} 元</span>
            </div>
            <div></div>
          </div>
          <div class="flex justify-between grey"
            v-if="Number(otherOrderData.other_order_deduct_amount) != 0">
            <div class="flex justify-between">
              <span>平台扣除:</span><span>-{{ otherOrderData.other_order_deduct_amount }} 元</span>
            </div>
            <div></div>
          </div>
          <div class="flex justify-between grey">
            <div class="flex justify-between">
              <span>运费:</span><span>{{ otherOrderData.other_order_freight_amount }} 元</span>
            </div>
            <div></div>
          </div>
          <div class="flex justify-between mb-2">
            <div class="flex justify-between font-bold">
              <span>第三方订单总价:</span><span>{{ otherOrderData.other_order_amount }} 元</span>
            </div>
            <div></div>
          </div>
          <div class="flex justify-between">
            <div class="flex justify-between">
              <span>补差:</span><span>{{ otherOrderData.other_order_add_amount }} 元</span>
            </div>
            <div>
              <a-button v-if="hasAllAuth" class="p-0" size="small" type="link" @click="handlerAddFlow(2)">添加</a-button>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex justify-between">
              <span>退款:</span><span>{{ Number(otherOrderData.other_order_refund_total) > 0 ? ("-"+otherOrderData.other_order_refund_total) : otherOrderData.other_order_refund_total }} 元</span>
            </div>
            <div>
              <a-button v-if="hasAllAuth" class="p-0" size="small" type="link" @click="handlerAddFlow(1)">添加</a-button>
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="flex justify-between items-center font-bold">
              <span>第三方流水总额:</span><span style="font-size:18px;">{{ otherTotalPrice }} 元</span>
            </div>
            <div>
              <a-button v-if="hasAllAuth" class="p-0" size="small" type="link" @click="isShowFlowLog=true">明细</a-button>
            </div>
          </div>
        </div>
      </div>
        
      <div class="flex justify-between mt-4">
        <span class="text-sm font-bold text-black">购买商品</span>
      </div>
      <base-table
        ref="orderThirdPartyTableRef"
        id="orderThirdPartyTableID"
        class="mt-2"
        :customHeight="300"
        :columnsData="columns_other_order_goods_list"
        rowKey="goods_sku_number"
        :tableData="other_order_goods_list"
      >
        <template #is_express="{text, record }">
          <a-switch v-if="hasAllAuth" :defaultChecked="text==2" @change="(b)=>onChangeExpress(b,record)" checked-children="是" un-checked-children="否" />
          <span v-else>{{ text==2 ? "是" : "否" }}</span>
        </template>
      </base-table>
    </div>

    <!-- 已忽略 -->
    <!-- <div v-if="otherOrderData.other_order_status==13" class="ignore mt-5 flex justify-center" style="align-items:center;">
      <a-button @click="handlerContinue" class="ml-4" type="primary">继续处理</a-button>
    </div> -->

    <!-- 未忽略 -->
    <div class="page-content innerpartydiv" 
        v-if="otherOrderData.other_order_status!=13 && order_list.length">
      <!-- 内部订单内容 -->
      <div class="inner-order" v-for="(item,i) in order_list" :key="i">
        <div class="order-title h3">
          <div class="flex items-center">
            <div class="l flex items-center">
              <!-- 已有的内部订单 -->
              <template v-if="item.order_no">
                <span v-if="item.order_type==1">自提</span>
                <span v-if="item.order_type==2">配送</span>
                <span> 订单ID： {{ item.order_no }} ({{ formatInnerOrderStatus(item.order_status, item.order_type) }})</span>
                <a-button class="ml-4 p-0" type="link" size="small" @click="handlerDetailBlank(item)">查看</a-button>
              </template>
              <!-- 新内部订单 -->
              <template v-else>
                <span v-if="otherOrderData.other_order_status < 21" class="l">内部订单</span>
                <span v-else>新内部订单</span>
                <a-select class="ml-4" style="width:100px"
                  v-model="item.order_type"
                  @change="handleChangeSelectOrderType(item)">
                  <a-select-option :value="1" :key="1">自提</a-select-option>
                  <a-select-option :value="2" :key="2">配送</a-select-option>
                </a-select>
              </template>
            </div>
            <template>
              <span v-if="item.audit_status==2" class="ml-3">已审核</span>
              <a-checkbox v-else class="ml-4" v-model="item.isAudit" @change="onCheckAudit(item)">审核通过</a-checkbox>
            </template>
          </div>
          <div>
            <template v-if="hasAllAuth && !item.disabled">
              <a-button type="primary" size="small" 
                  v-if="item.pick_up_delivery_order.goods_list.length>0" 
                  @click="deleteOrder(i)">解散包裹</a-button>
            </template>
            <span class="font-bold ml-4">订单金额：{{ (Number(item.order_goods_amount) + Number(item.freight_amount)).toFixed(2) }} 元</span>
          </div>
        </div>
        <div class="order-content">
          <a-row class="mt-3 dflex">
            <template v-if="item.order_type==1">
              <div class="dflex mr-4">
                <span class="label">取货人：</span>
                <div class="val">
                  <a-input allowClear placeholder="请输入收货人"
                    :disabled="item.disabled"
                    v-model="item.pick_up_delivery_order.pick_up_info.recipient_name"
                  ></a-input>
                </div>
              </div>
              <div class="dflex w-s">
                <span class="label">手机号：</span>
                <div class="val">
                  <a-input allowClear placeholder="请输入手机号"
                    :disabled="item.disabled"
                    v-model="item.pick_up_delivery_order.pick_up_info.recipient_phone"
                  ></a-input>
                </div>
              </div>
            </template>
            <template v-if="item.order_type==2">
              <div class="dflex mr-4">
                <span class="label">收货人：</span>
                <div class="val">
                  <a-input allowClear placeholder="请输入收货人"
                    :disabled="item.disabled"
                    v-model="item.pick_up_delivery_order.delivery_info.consignee_name"
                  ></a-input>
                </div>
              </div>
              <div class="dflex w-s">
                <span class="label">手机号：</span>
                <div class="val">
                  <a-input allowClear placeholder="请输入手机号"
                    :disabled="item.disabled"
                    v-model="item.pick_up_delivery_order.delivery_info.consignee_phone"
                  ></a-input>
                </div>
              </div>
            </template>
          </a-row>
          <a-row class="mt-3 dflex">
            <div class="dflex mr-4">
              <span class="label">下单人：</span>
              <div class="val">
                <a-input allowClear placeholder="请输入下单人"
                  :disabled="item.disabled"
                  v-model="item.place_order_name"
                ></a-input>
              </div>
            </div>
            <div class="dflex w-s">
              <span class="label">手机号：</span>
              <div class="val">
                <a-input allowClear placeholder="请输入下单手机号"
                  :disabled="item.disabled"
                  v-model="item.place_order_phone"
                ></a-input>
              </div>
            </div>
            <a-button class="ml-4" type="primary" size="small"
                    v-if="hasAllAuth && !item.disabled"
                    @click="handlerCopyContact(item)"
                    >{{ item.order_type==1 ? "同步取货人" : "同步收货人" }}</a-button>
          </a-row>
          <template v-if="item.order_type==1">
            <a-row class="mt-3 dflex">
              <div class="dflex w-m">
                <span class="label">自提时间：</span>
                <div class="val dflex">
                  <template v-if="hasAllAuth && !item.disabled">
                    <time-picker2 style="flex:1;" :value.sync="item.goods_time" />
                  </template>
                  <template v-else>
                    {{ item.pick_up_delivery_order.pick_up_info.pick_up_goods_time_start }}
                  </template>
                </div>
              </div>

              <div class="dflex w-s">
                <span class="label">自提门店：</span>
                <div class="val dflex">
                  <span class="mr-4">{{ item.pickup_shop_name  }}</span>
                  <a-button type="primary" size="small"
                    v-if="hasAllAuth && !item.disabled" 
                    @click="handlerShowShopList(item)">{{ item.pickup_shop_name ? "更换" : "选择" }}</a-button>
                </div>
              </div>
            </a-row>
          </template>
          <template v-if="item.order_type==2">
            <a-row class="mt-3 dflex">
              <div class="dflex" style="width:100%; max-width:800px;">
                <span class="label">收货地址：</span>
                <div class="val dflex">
                  <a-select class="flex-1" placeholder="请输入所在地址"
                      show-search
                      :disabled="item.disabled"
                      :default-active-first-option="false"
                      :show-arrow="false"
                      :filter-option="false"
                      :not-found-content="null"
                      v-model="item.consignee_address"
                      @search="handleSearchAddress"
                      @change="(value)=>{handleChangeAddress(value, item)}">
                    <a-select-option v-for="d in addressList" :key="d.id">{{ d.title }}<span class="addr-region">{{ d.address }}</span></a-select-option>
                  </a-select>
                  <a-input class="ml-2" style="width:300px" allowClear placeholder="单元/楼层/房号等"
                    :disabled="item.disabled"
                    v-model="item.consignee_address_detail"></a-input>
                </div>
              </div>
            </a-row>
            <a-row class="mt-3 dflex">
              <div class="dflex w-m">
                <span class="label">收货时间：</span>
                <div class="val dflex">
                  <template v-if="hasAllAuth && !item.disabled">
                    <time-picker2 style="flex:1;" :value.sync="item.goods_time" />
                  </template>
                  <template v-else>
                    {{ item.pick_up_delivery_order.delivery_info.delivery_time_start }}
                  </template>
                </div>
              </div>
              <div class="dflex">
                <div class="ml-4" style="color:#aaa;" 
                    v-if="item.delivery_outlets_id && item.delivery_outlets_id!=item.source_outlets_id">
                  （
                  <span>供货门店：</span>
                  <span v-if="item.source_outlets_name" class="mr-3">{{ item.source_outlets_name }}</span>
                  <a-button type="link" size="small" class="p-0" 
                    v-if="!item.order_no && item.delivery_outlets_type != 1"
                    @click="handlerShowShopList(item, true)">{{ item.source_outlets_name ? "更换" : "选择" }}</a-button>
                  ）
                </div>
                <span class="ml-4">配送网点： </span>
                <span class="mr-3">{{ item.delivery_outlets_name || "无" }}</span>
                <a-button type="primary" size="small"
                    v-if="hasAllAuth && !item.disabled"
                    @click="handlerShowShopList(item)"
                    >{{ item.delivery_outlets_name ? "更换" : "选择" }}</a-button>
              </div>
            </a-row>
          </template>
          <div class="flex justify-between mt-3">
            <span class="text-sm font-bold text-black">购买商品</span>
            <div v-if="hasAllAuth && !item.disabled">
              <a-button type="primary" size="small" 
                @click="handlerAddGoods(item)">添加商品</a-button>
              <a-button type="primary" size="small" class="ml-4"
                v-if="item.pick_up_delivery_order.goods_list.length>1" 
                @click="handlerSplitBtn(item)">拆分发货</a-button>
            </div>
          </div>
          <base-table
            ref="orderInnerTableRef"
            id="orderInnerID"
            class="mt-2"
            :customHeight="300"
            :columnsData="item.order_no ? columns_inner_edit : columns_inner_add"
            rowKey="keya"
            :tableData="item.pick_up_delivery_order.goods_list"
          >
            <template #number="{ record }">
              <!-- // test 蛋糕数字不能改？同sku的合并和拆分 -->
              <a-input-number
                :min="1" :step="1" :precision="0"
                :disabled="record.goods_type==3 || item.disabled"
                @change="(val)=>changeGoodNumber(val, item)" v-model="record.number"></a-input-number>
            </template>
            <template #order_price="{ record }">
              <a-input-number style="width:110px;" 
                :min="0" :step="0.01" :precision="2"
                :disabled="!hasAllAuth || item.disabled"
                @change="changeGoodOrderPrice(item)" v-model="record.order_price"></a-input-number>
            </template>
            <template #operation="{ index }">
              <a-button type="link" 
                  v-if="hasAllAuth && !item.disabled"
                  @click="handlerDeleteSku(index, item)">删除</a-button>
            </template>
          </base-table>

          <!-- 蛋糕配件 -->
          <div class="mt-3" v-if="item.pick_up_delivery_order.cake_accessory_list.length">
            <span class="text-sm font-bold text-black">蛋糕配件</span>
            <div v-for="(itemC, i) in item.pick_up_delivery_order.cake_accessory_list" :key="i * 100000">
              <div class="mt-2 flex items-center">
                <div class="ml-4 flex-1">
                  {{ itemC.goods_name }}
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(itemC, item)['flavor_name']}}</span>
                  <span class="ml-2" style="color:#aaa;">{{getCakeSkuInfo(itemC, item)['specification_name']}}</span>
                </div>

                <div class="flex ml-2">
                  <div>蜡烛:
                    <birthday-candle
                      :isDisabeld="item.disabled"
                      :value.sync="itemC.birthday_candle"></birthday-candle>
                  </div>
                  <div class="ml-2">餐具数量:
                    <a-select style="width:100px" 
                      :default-value="0" :disabled="item.disabled" 
                      v-model="itemC.diners_number" >
                      <a-select-option
                        v-for="(item, index) in tableWare"
                        :value="item" :key="index">{{ item }}人份</a-select-option>
                    </a-select>
                  </div>
                  <div class="ml-2">生日牌:
                    <a-auto-complete style="width:300px" placeholder="请输入"
                      :data-source="cardList"
                      :disabled="item.disabled"
                      v-model="itemC.birthday_card" />
                  </div>
                </div>
              </div>
            </div>
            
          </div>

          <a-row v-if="item.order_type==2" class="mt-3">
            <a-col :span="12" class="dflex">
              <span class="label" style="width:90px;">运费：</span>
              <div class="val">
                <a-input-number 
                  :min="0" :step="0.01" :precision="2"
                  :disabled="item.disabled"
                  v-model="item.freight_amount"></a-input-number>
                  元
                <a-checkbox v-if="canUsePlatFreight && order_list.length == 1" class="ml-2" 
                    v-model="isUsePlatFreight" @change="updateFreight(item)">使用平台运费</a-checkbox>
              </div>
            </a-col>
          </a-row>
          <a-row class="mt-3">
            <a-col :span="24" class="dflex">
              <span class="label">客服备注：</span>
              <div class="val">
                <a-input allowClear placeholder="请输入备注"
                  :disabled="item.disabled"
                  v-model="item.customer_service_remarks"
                ></a-input>
              </div>
              <template v-if="hasAllAuth">
                <a-button class="p-0 ml-2" type="link" 
                    @click="handlerCopyRemark(item,'order','customer')">复制下单备注</a-button>
                <a-button class="p-0 ml-2" type="link"
                    @click="handlerCopyRemark(item,'customer','customer')">复制客服备注</a-button>
              </template>
            </a-col>
          </a-row>
          <a-row class="mt-3">
            <a-col :span="24" class="dflex">
              <span class="label">内部备注：</span>
              <div class="val">
                <a-input allowClear placeholder="请输入备注"
                  :disabled="item.disabled"
                  v-model="item.private_remarks"
                ></a-input>
              </div>
              <template v-if="hasAllAuth">
                <a-button class="p-0 ml-2" type="link" 
                    @click="handlerCopyRemark(item,'order','inner')">复制下单备注</a-button>
                <a-button class="p-0 ml-2" type="link" 
                    @click="handlerCopyRemark(item,'customer','inner')">复制客服备注</a-button>
              </template>
            </a-col>
          </a-row>          
        </div>
      </div>
    </div>

    <!-- 底部bar：操作按钮 -->
    <div class="mb-5 flex justify-center" style="align-items:center;"
        v-if="hasAllAuth && otherOrderData.other_order_status != 13">
      <!-- 1 没有内部订单：建单/忽略 -->
      <template v-if="!order_list.length">
        <!-- 1.1 忽略（可以同步发货 运费补差订单需要） -->
        <a-checkbox class="mr-2" v-model="checkedThirdSend"
          v-if="!isTakeawayPlat && !isSended">同步第三方平台发货</a-checkbox>
        <a-button v-if="otherOrderData.other_order_status < 20" 
            class="mr-60" @click="handlerIgnore">忽略(不创建内部订单)</a-button>
        <div>
          <a-button @click="handlerCreateNewInner" type="primary">创建内部订单</a-button>
          <a-checkbox class="ml-2" 
            v-model="isUsePlatFreight">使用平台运费</a-checkbox>
        </div>
      </template>
      <!-- 2 有内部订单：保存 -->
      <template v-if="order_list.length">
        <!-- 保存 -->
        <template v-if="otherOrderData.other_order_status < 22">
          <template v-if="!isSended">
            <!-- 美团饿了么 同步发货 -->
            <a-checkbox class="mr-2" v-model="checkedThirdSend"
               v-if="[20,21].includes(otherOrderData.other_order_source)"
               disabled>
              <template v-if="otherOrderData.other_order_source == 21">同步饿了么“配送员已接单”</template>
              <template v-if="otherOrderData.other_order_source == 20">同步美团外卖“已分配配送员”</template>
            </a-checkbox>
            <a-checkbox class="mr-2" v-model="checkedThirdSend"
               v-if="otherOrderData.other_order_source == 27">
               同步抖音“配送员已接单（顾客可见）”
            </a-checkbox>
            <a-checkbox class="mr-2" v-model="checkedThirdSend"
               v-if="otherOrderData.other_order_source == 28">
               同步美团团购“备货完成（但顾客会看到‘配送中’）”
            </a-checkbox>
            <!-- 电商 同步发货 -->
            <a-checkbox class="mr-2" v-model="checkedThirdSend"
               v-else-if="!isTakeawayPlat">同步第三方平台已发货</a-checkbox>
          </template>
          <a-button @click="handlerSaveOrders" type="primary">保存全部订单数据</a-button>
        </template>
        <!-- 修改-不处理 -->
        <template v-else-if="dataUpdateMap.hasKeyUpdated">
          <a-button @click="handlerProcessed">我已知晓修改内容，从修改列表移出</a-button>
        </template>
      </template>
    </div>

  </div>

  <!-- 增减流水 -->
  <add-flow-modal @addPrice="okAddFlow" :flowType="addFlowType" :orderId="other_order_id" v-if="isShowAddFlow" :show.sync="isShowAddFlow" />
  <flow-log-modal :orderId="other_order_id" v-if="isShowFlowLog" :show.sync="isShowFlowLog" />

  <!-- 选门店、配送站 -->
  <select-shop-modal
    v-if="isSelectShop"
    :formData="innerListCurItem"
    :show.sync="isSelectShop"
    @ok="handleShopChanged"
  ></select-shop-modal>
  <select-outlets-modal
    v-if="isSelectOutlets"
    :formData="innerListCurItem"
    :show.sync="isSelectOutlets"
    @ok="handlerOutletsChanged"
  />
  <!-- 选商品 -->
  <add-goods-modal
    v-if="isSelectGoods"
    :show.sync="isSelectGoods"
    :outletsId="innerListCurItem.source_outlets_id"
    @ok="addGoodsOk"
  ></add-goods-modal>

  <!-- 拆分包裹 -->
  <split-order-modal
    v-if="isShowSplitOrder"
    :show.sync="isShowSplitOrder"
    :goodsList="goodsListInSplitModal"
    @ok="handlerSplitOrder"
  />

  <!-- 售后 -->
  <RefundApply v-if="isShowRefundApply" :show.sync="isShowRefundApply" :otherOrderId="other_order_id" :data="refundApplyForm" />

  <!-- 快递详情 -->
  <express-detail
       v-if="isShowExpressDetail"
       :show.sync="isShowExpressDetail"
       :order_no="otherOrderData.other_order_no"></express-detail>

</div>
</template>

<script>
import { 
  formatOtherOrderStatus,
  formatOtherOrderSource,
  formatDetailOrderStatus,
  formatInnerOrderStatus,
  tableWare
} from '@/utils/type'
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'
import TimePicker2 from "@/components/order/time-picker2.vue"
import BirthdayCandle from '@/components/order/birthday-candle.vue'
import selectShopModal from '../order/components/select-shop-modal.vue'
import selectOutletsModal from './components/select-outlets-modal.vue'
import AddGoodsModal from '../order/components/add-goods-modal.vue'

import { getCityList } from "@/api/outlets"
import { getPickUpOutletsList } from '@/api/order/create'

import {
  otherOrderGetDetail,
  otherOrderSyncDelivery,
  otherOrderIgnore,
  otherOrderContinue,
  otherOrderCancel,
  updateOtherOrderGoodsExpressStatus,
  otherOrderEdit,
  otherOrderProcessed,
} from "@/api/customer"
import { filterSkuList } from "./addGoods"
import { getMapPointList, getAdcode, getDistance } from "@/utils/tMap"
import { cardList, isTakeawayPlat } from "@/utils/type"
import { checkPage } from '@/utils/authData.js'
import { checkAdcodes } from "@/utils/address.js"
import { searchShopDeliveryArea } from "@/utils/delivery.js"
import AddFlowModal from './components/add-flow-modal.vue'
import FlowLogModal from './components/flow-log-modal.vue'
import RefundApply from "./components/refund-apply"
import SplitOrderModal from './components/split-order-modal.vue'
import ExpressDetail from "../../express/components/detail"

import { columns_other_order_goods_list, columns_inner_add } from './columns'

export default {
  components: {
    TimePicker2,
    // TpointPicker,
    BirthdayCandle,
    selectShopModal,
    selectOutletsModal,
    AddFlowModal,
    FlowLogModal,
    AddGoodsModal,
    RefundApply,
    SplitOrderModal,
    ExpressDetail
  },
  data() {
    return {
      cardList,
      tableWare,
      columns_other_order_goods_list: [],
      columns_inner_add,
      columns_inner_edit: columns_inner_add.filter(el=>!["goods_price"].includes(el.dataIndex)),
      // orderNo: '',
      other_order_id: '',
      onlyRead: false,

      isTakeawayPlat: false,

      isShowAddFlow: false, //添加退费流水，补差流水
      isShowFlowLog: false, //查看流水记录
      isShowSplitOrder: false, //拆分发货
      isShowRefundApply: false, //售后退款
      refundApplyForm: [],
      isShowExpressDetail: false,

      innerListCurItem: {},
      addressList: [],
      cityList: [],
      isSelectShop: false, //选择门店
      isSelectOutlets: false, 
      isSelectGoods: false, //添加商品

      checkedThirdSend: false,
      isSended: false,

      otherOrderData: null,
      other_order_goods_list: [], // 第三方订单商品列表
      other_order_new_update_data: {},
      order_list: [], //内部订单列表
      addFlowType: '',

      dataUpdateMap: {},

      hasAllAuth: checkPage("/other-order"),

      isCityManage: isCityManage(),

      goodsListInSplitModal: [], //拆分发货弹窗里的
      cancel_order_data: [], //取消的订单数据

      // ignore: "", //不处理此订单

      canUsePlatFreight: false,
      isUsePlatFreight: false,
      
      isCanCancel: false, // 可以取消订单

      open_time: '',

      isLoading: false,
    }
  },

  created() {
    this.other_order_id = this.$route.query.other_order_id || ''
    this.other_order_no = this.$route.query.other_order_no || ''

    if(this.$route.query.onlyread){ // 已完成时
      this.onlyRead = true
    }
  },
  computed: {
    otherTotalPrice() {
      let price = Number(this.otherOrderData.other_order_amount)
            + Number(this.otherOrderData.other_order_add_amount)
            - Number(this.otherOrderData.other_order_refund_total)
      return Math.round(price*100)/100
    },
  },
  mounted() {
    this.initCityList()
    this.initData()
  },

  methods: {
    formatOtherOrderStatus,
    formatOtherOrderSource,
    formatDetailOrderStatus,
    formatInnerOrderStatus,

    checkNewUpdateData() {
      let data = {}
      Object.keys(this.other_order_new_update_data).map(key=>{
        if(this.otherOrderData[key] != this.other_order_new_update_data[key]){
          data.hasKeyUpdated = true
          // data[key] = true
          data[key] = "修改前：" + (this.otherOrderData[key] || "空")
          this.otherOrderData[key] = this.other_order_new_update_data[key]
        }
      })
      this.dataUpdateMap = data
    },

    handlerCreateNewInner() {
      let item = this.createInnerOrderEmpty()

      this.order_list.push(item)
      this.updateFreight()
    },

    handlerSplitBtn(item) {
      this.innerListCurItem = item
      let canSplitList = []
      item.pick_up_delivery_order.goods_list.forEach((el) => {
        // if(index >0) {
          canSplitList.push(el)
        // }
      })
      this.isShowSplitOrder = true
      this.goodsListInSplitModal = canSplitList
    },

    createInnerOrderEmpty(outlet_order, isSplit) {
      // console.log("createInnerOrderEmpty", this.otherOrderData)
      let item = {
        "order_no": "",
        "order_type": 2,
        "order_status": "",
        "freight_amount": 0,
        // "customer_service_remarks": "",
        "customer_service_remarks": this.otherOrderData.other_order_remarks,
        "private_remarks": "",
        "pick_up_delivery_order": {
            "suborder_type": 2,
            "outlets_id": "",
            "outlets_name": "",
            "outlets_business_hours":"",
            "outlets_today_business_hours":"",
            "outlets_delivery_hours":"",
            "outlets_today_delivery_hours":"",
            "pick_up_info": {
                "pick_up_goods_time_start": "",
                "pick_up_goods_time_end": "",
                "recipient_name": "",
                "recipient_phone": ""
            },
            "delivery_info": {
                "delivery_time_start": "",
                "delivery_time_end": "",
                "consignee_name": "",
                "consignee_phone": "",
                "consignee_lng_lat": "",
                "consignee_adcode": "",
                "consignee_address": "",
                "consignee_address_detail": "",
            },
            "goods_list": [],
            "cake_accessory_list": []
        },
        "consignee_lng": "",
        "consignee_lat": "",
        "consignee_adcode": "",
        "consignee_address": "",
        "consignee_address_detail": "",
        "goods_time": [],
        "order_goods_amount": 0,
      }
      item.city = {
          label: '',
          value: 0
      }
      // item.order_type = 2
      // item.goods_time = []

      if(outlet_order){
        if(outlet_order.order_type == 1){
          let pick_info = outlet_order.pick_up_delivery_order.pick_up_info
          item.pick_up_delivery_order.pick_up_info.recipient_name   = pick_info.recipient_name
          item.pick_up_delivery_order.pick_up_info.recipient_phone  = pick_info.recipient_phone
          item.pick_up_delivery_order.delivery_info.consignee_name  = pick_info.recipient_name
          item.pick_up_delivery_order.delivery_info.consignee_phone = pick_info.recipient_phone
        }else if(outlet_order.order_type == 2){
          let dlvr_info = outlet_order.pick_up_delivery_order.delivery_info
          item.pick_up_delivery_order.pick_up_info.recipient_name     = dlvr_info.consignee_name
          item.pick_up_delivery_order.pick_up_info.recipient_phone    = dlvr_info.consignee_phone
          item.pick_up_delivery_order.delivery_info.consignee_name    = dlvr_info.consignee_name
          item.pick_up_delivery_order.delivery_info.consignee_phone   = dlvr_info.consignee_phone
          item.consignee_lng      = dlvr_info.consignee_lng_lat.split(' ')[0]
          item.consignee_lat      = dlvr_info.consignee_lng_lat.split(' ')[1]
          item.consignee_adcode   = dlvr_info.consignee_adcode
          item.consignee_address  = dlvr_info.consignee_address
        }
      }else{
        const name = this.otherOrderData.other_order_consignee_name
        const phone = this.otherOrderData.other_order_consignee_phone
        item.pick_up_delivery_order.pick_up_info.recipient_name   = name
        item.pick_up_delivery_order.pick_up_info.recipient_phone  = phone
        item.pick_up_delivery_order.delivery_info.consignee_name  = name
        item.pick_up_delivery_order.delivery_info.consignee_phone = phone
        item.pick_up_delivery_order.delivery_info.consignee_name  = name
        // 下单人
        if(this.otherOrderData.other_order_place_order_phone){
          // 饿了么有下单人
          item.place_order_phone = this.otherOrderData.other_order_place_order_phone
          item.place_order_name = this.otherOrderData.other_order_place_order_name || this.otherOrderData.other_order_place_order_phone
        }else{
          // 默认和收货人一致
          item.place_order_name = name
          item.place_order_phone = phone
        }
      }

      if(item.order_type == 2){
        this.autoFigureAddr(item, isSplit)
      }

      // 外卖平台：自提/配送时间
      if(this.otherOrderData.other_order_type == 1){
        if(this.otherOrderData.other_order_pick_up_goods_time_start){
          const pick_date = this.otherOrderData.other_order_pick_up_goods_time_start.substr(0,10)
          const pick_time_start = this.otherOrderData.other_order_pick_up_goods_time_start.substr(11,5)
          const pick_time_end = this.otherOrderData.other_order_pick_up_goods_time_end.substr(11,5)
          item.goods_time = [pick_date, pick_time_start+"-"+pick_time_end]
        }
      }else if(this.otherOrderData.other_order_type == 2){
        if(this.otherOrderData.other_order_delivery_time_start){
          const delivery_date = this.otherOrderData.other_order_delivery_time_start.substr(0,10)
          const delivery_time_start = this.otherOrderData.other_order_delivery_time_start.substr(11,5)
          const delivery_time_end = this.otherOrderData.other_order_delivery_time_end.substr(11,5)
          item.goods_time = [delivery_date, delivery_time_start+"-"+delivery_time_end]
        }
      }
      
      return item
    },

    handlerSplitOrder(selectRows) {
      //order_list 添加一个订单
      let item = this.createInnerOrderEmpty(this.innerListCurItem, true)

      item.pick_up_delivery_order.goods_list = selectRows
      this.order_list.push(item)
      //添加完毕，更新被移出商品的订单
      this.updateAfterSplit(selectRows)
      this.updateFreight()
    },

    updateAfterSplit(selectRows) {
      let removeids = []
      selectRows.map(el=>{
        removeids.push(el.goods_sku_number+"-"+el.keya)
      })
      this.innerListCurItem.pick_up_delivery_order.goods_list = 
        this.innerListCurItem.pick_up_delivery_order.goods_list.filter(el=>{
          return !removeids.includes(el.goods_sku_number+"-"+el.keya)
        })
      this.updateCakeList(this.innerListCurItem)
      this.computedPrice(this.innerListCurItem)
      //更新完被移除的，然后更新新添加的
      let newCurItem = this.order_list[this.order_list.length-1]
      this.updateCakeList(newCurItem)
      this.computedPrice(newCurItem)
    },

    onCheckAudit(){
      this.$forceUpdate()
    },

    updateFreight(orderItem){
      if(orderItem){
        if(this.isUsePlatFreight){
          orderItem.freight_amount = Number(this.otherOrderData.other_order_freight_amount)
        }else{
          orderItem.freight_amount = orderItem.area_freight_amount || 0
        }
      }else{
        if(this.order_list.length > 1 && this.isUsePlatFreight){
          this.isUsePlatFreight = false
          this.order_list.maps((el,index)=>{
            if(el.order_type == 2){
              if(index > 0){
                el.freight_amount = el.area_freight_amount || 0
              }  
            }
          })
        }  
      }
    },

    handlerDetailBlank(row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },
    
    handleChangeSelectOrderType(subOrder) {
      if(subOrder.order_type == 1){
        subOrder.freight_amount = 0
      }
      if(subOrder.order_type == 2){
        if(subOrder.cacheArea){
          subOrder.freight_amount = subOrder.cacheArea.freight
        }
      }
      
      this.$forceUpdate()
    },

    //不处理此订单
    async handlerIgnore() {
      let params = {
        other_order_id: this.other_order_id
      }
      const { code } = await otherOrderIgnore(params)
      if (code == 0) {
        this.$message.success("该订单已进入不处理列表")
        // setTimeout(()=>{
        //   this.$router.push({path: '/order-thirdparty-detail', query: {other_order_id: this.other_order_id, ignore: 1}})
        // },2000)
        // 同步第三方平台发货
        if(this.checkedThirdSend) {
          await this.syncDelivery()
          this.initData()
        }else{
          this.initData()
        }
      }
    },

    //继续处理
    async handlerContinue() {
      let params = {
        other_order_id: this.other_order_id
      }
      const { code } = await otherOrderContinue(params)
      if (code == 0) {
        // this.$router.push({path: '/order-thirdparty-detail', query: {other_order_id: this.other_order_id, ignore: 0}})
        this.$message.success("已恢复")
        this.initData()
      }
    },

    //取消第三方订单
    async doCancel() {
      let params = {
        other_order_id: this.other_order_id
      }
      const { code } = await otherOrderCancel(params)
      if (code == 0) {
        // this.$router.go(-1)
        this.$message.success("已取消")
        this.initData()
      }
    },    

    //同步第三方平台发货
    async syncDelivery() {
      let params = {
        other_order_id: this.other_order_id
      }
      const { code } = await otherOrderSyncDelivery(params)
      if (code == 0) {
        this.$message.success("已同步第三方发货")
      }
    },

    handlerAddFlow(flow_type) {
      //1-退款流水 
      //2-补差流水
      this.addFlowType = flow_type
      this.isShowAddFlow = true
    },

    okAddFlow(value) {
      // debug 应该刷新详情接口来变更这个数据
      if(this.addFlowType == 1) {
        this.otherOrderData.other_order_refund_total =
        Number(this.otherOrderData.other_order_refund_total) + Number(value)
      }
      if(this.addFlowType == 2) {
        this.otherOrderData.other_order_add_amount = 
        Number(this.otherOrderData.other_order_add_amount) + Number(value)
      }
    },

    handlerRefundApply() {
      let arr = []
      this.order_list.forEach(el => {
        let obj = {
          order_type: el.order_type,
          order_no: el.order_no,
          goods_list: el.pick_up_delivery_order.goods_list,
          // order_amount: '',
          freight_amount: el.freight_amount,
        }

        let order_amount = 0
        el.pick_up_delivery_order.goods_list.forEach(e=>{
          order_amount += e.number * Number(e.order_price)
        })

        obj.order_amount = order_amount
        arr.push(obj)
      })
      this.refundApplyForm = arr
      this.isShowRefundApply = true
    },

    async initData() {
      // 重置数据
      this.cancel_order_data = []
      
      // 请求数据
      let { data, code, timestamp } = await otherOrderGetDetail({
        other_order_id: this.other_order_id,
        other_order_no: this.other_order_no,
      })
      
      if(code != 0) return;
      
      // debug 测试地址计算
      // data.other_order_consignee_address = "北京，海淀区，中关村街道，，北京海淀区中关村街道中关村南三街6号中科资源大厦北楼中进汽贸"
      // data.other_order_consignee_address = "北京，北京市，朝阳区，，北京市朝阳区朝阳北路101号大悦城"
      // data.other_order_consignee_address = "北京，北京市，东城区，龙潭街道，龙潭街道首开中晟馨苑4号院3号楼1402"
      // data.other_order_new_update_data.other_order_consignee_address = data.other_order_consignee_address

      // // debug 某个sku买了多个
      // if(data.other_order_id == "74"){
      //   data.other_order_goods_list[0].goods_sku_number = "F00094"
      //   data.other_order_goods_list[0].goods_type = 3
      //   data.other_order_goods_list[0].number = 2
      // }

      // 建单默认使用平台运费
      this.canUsePlatFreight = !data.order_list.length

      // 预处理：第三方平台
      this.isTakeawayPlat = isTakeawayPlat(data.other_order_source)
      this.columns_other_order_goods_list = this.isTakeawayPlat ? columns_other_order_goods_list.filter(el=>!['is_express'].includes(el.dataIndex)) : columns_other_order_goods_list
      if(this.isCityManage){
        const manageCityList = await getMarketCityList(true)
        this.hasAllAuth = checkPage("/other-order") && (manageCityList.some(el=>el.adcode == data.outlets_city_adcode))
      }
      if(this.isTakeawayPlat){
        if(data.other_order_type == 1){
          if(data.other_order_pick_up_goods_time_start == data.other_order_pick_up_goods_time_end){
            data.otherShowTime = data.other_order_pick_up_goods_time_start.substr(0,16)+" 前"
          }else{
            data.otherShowTime = data.other_order_pick_up_goods_time_start.substr(0,16)+" ~ "+data.other_order_pick_up_goods_time_end.substr(0,16)
          }
        }
        if(data.other_order_type == 2){
          if(data.other_order_delivery_time_start == data.other_order_delivery_time_end){
            data.otherShowTime = data.other_order_delivery_time_start.substr(0,16)+" 前"
          }else{
            data.otherShowTime = data.other_order_delivery_time_start.substr(0,16)+" ~ "+data.other_order_delivery_time_end.substr(0,16)
          }
        }
      }
      
      // 预处理：单价
      data.other_order_goods_list.map(el=>{
        el.single_order_price = Math.round(Number(el.order_price) / el.number * 100) / 100
      })

      this.open_time = timestamp
      this.otherOrderData = data
      this.other_order_id = data.other_order_id
      this.other_order_goods_list = data.other_order_goods_list
      this.other_order_new_update_data = data.other_order_new_update_data

      data.order_list.forEach((element, index) => {
        element.isAudit = element.audit_status == 2
        element.order_type = element.pick_up_delivery_order.suborder_type
        // 自提/配送时间
        let date = "", time = ""
        let time_start, time_end
        if(element.order_type == 1){ // 自提
          time_start = element.pick_up_delivery_order.pick_up_info.pick_up_goods_time_start
          time_end   = element.pick_up_delivery_order.pick_up_info.pick_up_goods_time_end
          element.pickup_shop_id = element.pick_up_delivery_order.outlets_id
          element.pickup_shop_name = element.pick_up_delivery_order.outlets_name
        }else{ // 配送单
          time_start = element.pick_up_delivery_order.delivery_info.delivery_time_start
          time_end   = element.pick_up_delivery_order.delivery_info.delivery_time_end
          element.delivery_outlets_id = element.pick_up_delivery_order.outlets_id
          element.delivery_outlets_name = element.pick_up_delivery_order.outlets_name
        }
        element.isTimePoint = time_start&&(time_start==time_end)
        if(time_start){
          date = time_start.split(" ")[0]
          time = time_start.split(" ")[1].substr(0, 5) + "-" + time_end.split(" ")[1].substr(0, 5)
        }
        element.goods_time = [date, time]

        element.city = {
          label: '',
          value: 0
        }
        element.consignee_address = element.pick_up_delivery_order.delivery_info.consignee_address || ""
        element.consignee_adcode = element.pick_up_delivery_order.delivery_info.consignee_adcode || ""
        if(element.pick_up_delivery_order.delivery_info.consignee_lng_lat){
          element.consignee_lng = element.pick_up_delivery_order.delivery_info.consignee_lng_lat.split(' ')[0]
          element.consignee_lat = element.pick_up_delivery_order.delivery_info.consignee_lng_lat.split(' ')[1]
        }

        element.pick_up_delivery_order.goods_list.map((el,i)=>{
          el.keya = "key"+index+i
        })

        element.disabled = (element.order_status < 1 || element.order_status >= 30)
        
        this.$forceUpdate()
      })

      this.order_list = data.order_list
      this.computedPrice()

      // 是否可以取消订单
      let isCanCancel = false
      let _has_inner_order_completed = data.order_list.some(el=>el.order_status>=30)
      if(this.isTakeawayPlat){
        // 饿了么：超时会自动完成订单、然后顾客发起售后、程序无法自动创建售后单（内部订单未完成 不会自动创建内部售后单）
        if(data.other_order_source == 21){
          // 饿了么、内部单未完成、外部已建单未完成、外部单退款了
          if(!_has_inner_order_completed && data.other_order_status == 21 && this.otherTotalPrice==0){
            isCanCancel = true
          }
        }
      }else{
        // 电商订单：内部单未完成 & 外部单不是“已建单-已取消”
        if(!_has_inner_order_completed && data.other_order_status != 22){
          isCanCancel = true
        }
      }
      this.isCanCancel = isCanCancel


      this.isSended = data.other_order_delivery_status == 2
      // 美团饿了么 自动同步建单
      if(!this.isSended && [20,21].includes(this.otherOrderData.other_order_source)){
        this.checkedThirdSend = true
      }else{
        this.checkedThirdSend = false
      }

      this.checkNewUpdateData()
    },

    async initCityList () {
      const { data, code } = await getCityList()
      if (code == 0) {
        this.cityList = data.list
      }
    },

    handleAddBackPrice(value) {
      this.thirdpart.tkprice += value
    },

    handleAddSupPrice(value) {
      this.thirdpart.supprice += value
    },

    async onChangeExpress(val, row){
      let params = {
        other_order_id: this.other_order_id,
        other_order_goods_id: row.other_order_goods_id, 
      }
      if(val) {
        //开
        params.is_express = 2
      }else{
        //关闭
        params.is_express = 1
      }
      const { code } = await updateOtherOrderGoodsExpressStatus(params)
      if (code == 0) {
        row.is_express = params.is_express
      }
    },

    handlerShowShopList (item, isShop) {    
      this.innerListCurItem = item

      if (this.innerListCurItem.order_type == 2) {
        if (this.innerListCurItem.consignee_lat && this.innerListCurItem.consignee_lng) {
          if(isShop){
            this.isSelectShop = true
          }else{
            this.isSelectOutlets = true;
          }
        } else {
          this.$message.warning('请先输入收货地址')
        }
      } else {
        this.isSelectShop = true
      }
    },
    handleShopChanged (r) {
      if(r){
        this.innerListCurItem.source_outlets_id   = r.outlets_id
        this.innerListCurItem.source_outlets_name = r.outlets_name
        if(this.innerListCurItem.order_type == 1){
          this.innerListCurItem.pickup_shop_id   = r.outlets_id
          this.innerListCurItem.pickup_shop_name = r.outlets_name
        }
      }
      this.$forceUpdate()
      this.computedPrice(this.innerListCurItem)
    },

    handlerOutletsChanged(res){
      console.log('handlerOutletsChanged', res)
      if(res){
        this.innerListCurItem.delivery_outlets_type = res.outlets_type
        this.innerListCurItem.delivery_outlets_id   = res.outlets_id
        this.innerListCurItem.delivery_outlets_name = res.outlets_name
        this.innerListCurItem.delivery_region_id    = res.delivery_region_id
        this.innerListCurItem.area_freight_amount = res.freight
        // this.innerListCurItem.freight_amount = res.freight
        this.updateFreight(this.innerListCurItem)
        if(!this.innerListCurItem.order_no && res.outlets_type == 1){
          this.innerListCurItem.source_outlets_id   = res.outlets_id
          this.innerListCurItem.source_outlets_name = res.outlets_name
        }
      }
      this.computedPrice(this.innerListCurItem)
    },

    // 计算订单价格
    computedPrice(order) {
      console.log("computedPrice")
      // debug 缺少按照供货网点 重刷价格的流程

      //价格可手动修改，最后提交的时候计算
      if(order){
        let order_goods_amount = 0
        let goods_list = order.pick_up_delivery_order.goods_list || []
        goods_list.map(gd=>{
          order_goods_amount += Number(gd.order_price)*gd.number
        })
        order.order_goods_amount = Math.round(order_goods_amount*100)/100
      }else{
        this.order_list.map(el=>{
          this.computedPrice(el)
        })
      }
    },

    async autoFigureAddr(orderItem, isSplit){
      // 1. 确定经纬度
      if(!orderItem.consignee_lat){
        let addr = this.otherOrderData.other_order_consignee_address || ""
        let addrList = []
        addr.split("，").map(el=>{
          if(el) addrList.push(el)
        })
        // 第三方收货地址格式化：省/市/区县/街道（取4位有效单位）
        let len = Math.min(addrList.length, 4)
        let platAddr = addrList.splice(0, len).join("")

        // 1.1 如果是外卖平台：直接就有经纬度
        if(this.otherOrderData.other_order_consignee_lng_lat){
          const lng_lat = this.otherOrderData.other_order_consignee_lng_lat.split(" ")
          orderItem.consignee_lng = lng_lat[0]
          orderItem.consignee_lat = lng_lat[1]
          if(len < 3){
            orderItem.consignee_address = ""
            orderItem.consignee_address_detail = this.otherOrderData.other_order_consignee_address || ""
            // 经纬度计算省市区县（顺带adcode也有了）（外卖平台地址不全）
            const adcodesRes = await getAdcode(orderItem.consignee_lat, orderItem.consignee_lng)
            if (adcodesRes && adcodesRes.status == 0) {
              if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
                orderItem.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
              } else {
                orderItem.consignee_adcode = adcodesRes.result.ad_info.adcode
              }
              orderItem.consignee_address = adcodesRes.result.address
            }
          }else{
            let platAddrDetail = addrList.join("，")
            if(platAddrDetail.indexOf(platAddr) == 0){
              platAddrDetail = platAddrDetail.slice(platAddr.length)
              orderItem.consignee_address = platAddr
              orderItem.consignee_address_detail = platAddrDetail
            }
          }
        }else{
        // 1.2 非外卖平台：通过地址描述 计算经纬度
          if(len < 3) {
            console.log("第三方的收货地址 不正常啊")
            return
          }
          
          let platAddrDetail = addrList.join("，")
          if(platAddrDetail.indexOf(platAddr) == 0){
            platAddrDetail = platAddrDetail.slice(platAddr.length)
          }
          const res = await getMapPointList(platAddr)
          if(res.status != 0 || !res.data[0]) {
            this.$message.warning("收货地址无法识别，请修改地址再试");
            return
          }
          
          const item = res.data[0]
          orderItem.consignee_address = platAddr
          orderItem.consignee_address_detail = platAddrDetail
          orderItem.consignee_lat = item.location.lat
          orderItem.consignee_lng = item.location.lng
        }
      }

      // 2. 根据经纬度反查Adcode
      if(!orderItem.consignee_adcode){
        const adcodesRes = await getAdcode(orderItem.consignee_lat, orderItem.consignee_lng)
        if (adcodesRes && adcodesRes.status == 0) {
          if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
            orderItem.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
          } else {
            orderItem.consignee_adcode = adcodesRes.result.ad_info.adcode
          }
        }
      }

      if(!orderItem.consignee_adcode) return
      
      for (let i in this.cityList) {
        let v = checkAdcodes(orderItem.consignee_adcode, this.cityList[i].adcode)
        if (v == 1 || v == 0) {
          orderItem.city = {
            label: this.cityList[i].name,
            value: this.cityList[i].adcode,
          }
          break
        }
      }

      // 计算配送点
      let area = await searchShopDeliveryArea({
        lat: orderItem.consignee_lat,
        lng: orderItem.consignee_lng
      }, orderItem.consignee_adcode)

      orderItem.cacheArea = area
      if (area) {
        orderItem.delivery_outlets_type = area.outlets_type
        orderItem.delivery_outlets_id   = area.outlets_id
        orderItem.delivery_outlets_name = area.outlets_name
        orderItem.delivery_region_id    = area.delivery_region_id
        // orderItem.freight_amount = area.freight
        orderItem.area_freight_amount = area.freight
        this.updateFreight(orderItem)
        // this.computedPrice(orderItem)
        // if(this.otherOrderData.other_order_status < 21){
        //   this.autoAddGoods(orderItem.outlets_id)
        // }
      } else {
        orderItem.delivery_outlets_name = ''
        orderItem.delivery_outlets_id = ''
        orderItem.delivery_region_id = '';
        orderItem.freight_amount = 0
        orderItem.area_freight_amount = 0
        this.$message.warning("地址不在配送范围内，请手动查询精确地址试试");
      }

      this.innerListCurItem = orderItem

      // 供货网点
      await this.autoFigureShop(area)

      // 添加商品
      // if(area && orderItem.source_outlets_id){
      if(orderItem.source_outlets_id && !isSplit){
        if(this.otherOrderData.other_order_status < 21){
          this.autoAddGoods(orderItem.source_outlets_id)
        }
      }
    },

    async autoFigureShop(area){
      // 1. 如果是外卖平台 携带了下单网点：那就是供货网点
      if(this.otherOrderData.outlets_id){
        this.handleShopChanged({
          outlets_id: this.otherOrderData.outlets_id,
          outlets_name: this.otherOrderData.outlets_name,
        })
        return
      }

      // 2. 如果配送网点是门店：那就是供货网点
      if(area && area.outlets_type == 1){
        this.handleShopChanged(area)
        return
      }

      // 3. 按照收货地址 就近找一个门店作为供货网点
      const orderItem = this.innerListCurItem
      // UI显示重置
      orderItem.source_outlets_id = ""
      orderItem.source_outlets_name = "计算中..."
      let { code, data } = await getPickUpOutletsList({
        adcode: orderItem.consignee_adcode
      })
      orderItem.outlets_name = ""

      if(code == 0){
        let arr = data.list.map(item => {
          item.distance = getDistance(
            orderItem.consignee_lat, 
            orderItem.consignee_lng,
            item.outlets_lat_lng.split(' ')[1], 
            item.outlets_lat_lng.split(' ')[0]
          ).toFixed(2)
          return item
        })
        arr.sort((a,b)=>{return a.distance-b.distance})
        const shop = arr && arr[0]
        if(shop){
          this.handleShopChanged(shop)
        } 
      }
    },

    async autoAddGoods(outlets_id){
      let skus = this.other_order_goods_list.filter(el=>el.is_express!=2)
      // test 这块代码写的不好（筛选和数量分两步处理的 应该可以合并吧）
      let sku_nums = skus.map(el=>{return el.goods_sku_number})
      let skuList = await filterSkuList(sku_nums, outlets_id)
      // sku的数量和单价
      const arr = []
      skuList.map(sku=>{
        const s = skus.find(el=>el.goods_sku_number==sku.goods_sku_number)
        if(s) {
          if(s.goods_type == 3){
            let count = s.number || 1
            while(count-- > 0){
              arr.push(Object.assign({}, sku, {
                number: 1,
                birthday_card: s.birthday_card || "", // 外卖-饿了么 可能会有祝福语
                // order_price: s.single_order_price
              }))
            }
          }else{
            arr.push(Object.assign({}, sku, {
              // order_price: s.single_order_price,
              number: s.number
            }))
          } 
        }
      })
      if(arr.length) {
        // 如果是外卖-饿了么/美团外卖，解析餐具（算到第一个蛋糕上）
        if([20,21].includes(this.otherOrderData.other_order_source)){
          const remarks = this.otherOrderData.other_order_remarks || ""
          if(/([\d]*)份餐具/.test(remarks)){
            const diners_number = parseInt(RegExp.$1) || 0
            if(diners_number){
              const cake = arr.find(el=>el.goods_type == 3)
              if(cake){
                cake.diners_number = diners_number
              }
            }
          } 
        }
        // 如果是外卖-抖音，解析餐具（算到第一个蛋糕上）
        if([27].includes(this.otherOrderData.other_order_source)){
          const remarks = this.otherOrderData.other_order_remarks || ""
          if(/\[餐具\]([\d]*)份/.test(remarks)){
            const diners_number = parseInt(RegExp.$1) || 0
            if(diners_number){
              const cake = arr.find(el=>el.goods_type == 3)
              if(cake){
                cake.diners_number = diners_number
              }
            }
          } 
        }
        // 如果是外卖-美团团购，解析餐具（算到第一个蛋糕上）
        if([28].includes(this.otherOrderData.other_order_source)){
          const remarks = this.otherOrderData.other_order_remarks || ""
          if(/「餐具数量」([\d]*)份/.test(remarks)){
            const diners_number = parseInt(RegExp.$1) || 0
            if(diners_number){
              const cake = arr.find(el=>el.goods_type == 3)
              if(cake){
                cake.diners_number = diners_number
              }
            }
          } 
        }
        
        this.addGoodsOk(arr)
      }
    },

    // 地址输入
    handleSearchAddress (value) {
      getMapPointList(value).then((res) => {
        if (res.status == 0) {
          this.addressList = res.data.map(item => {
            item.region = item.province + item.city + item.district
            return item
          })
        }else{
          this.addressList = []
        }
      })
    },

    async handleChangeAddress (value, curItem) {
      this.innerListCurItem = curItem

      const item = this.addressList.find((item) => item.id === value)
      this.innerListCurItem.consignee_address = item.address + item.title
      this.innerListCurItem.consignee_lat = item.location.lat
      this.innerListCurItem.consignee_lng = item.location.lng
      // 根据经纬度反查Adcode
      const adcodesRes = await getAdcode(item.location.lat, item.location.lng)
      if (adcodesRes && adcodesRes.status == 0) {
        if (adcodesRes.result.address_reference && adcodesRes.result.address_reference.town) {
          this.innerListCurItem.consignee_adcode = adcodesRes.result.address_reference.town.id + '000'
        } else {
          this.innerListCurItem.consignee_adcode = adcodesRes.result.ad_info.adcode
        }
        for (let i in this.cityList) {
          let v = checkAdcodes(this.innerListCurItem.consignee_adcode, this.cityList[i].adcode)
          if (v == 1 || v == 0) {
            this.innerListCurItem.city = {
              label: this.cityList[i].name,
              value: this.cityList[i].adcode,
            }
            break
          }
        }

        // 计算配送点
        let area = await searchShopDeliveryArea({
          lat: this.innerListCurItem.consignee_lat,
          lng: this.innerListCurItem.consignee_lng
        }, this.innerListCurItem.consignee_adcode)

        this.innerListCurItem.cacheArea = area
        if (area) {
          // this.innerListCurItem.outlets_id = area.outlets_id
          this.innerListCurItem.delivery_outlets_type = area.outlets_type
          this.innerListCurItem.delivery_outlets_id   = area.outlets_id
          this.innerListCurItem.delivery_outlets_name = area.outlets_name
          this.innerListCurItem.delivery_region_id    = area.delivery_region_id
          this.innerListCurItem.freight_amount = area.freight
          this.innerListCurItem.area_freight_amount = area.freight
          this.updateFreight(this.innerListCurItem)
          // this.computedPrice(this.innerListCurItem)
        } else {
          // this.innerListCurItem.outlets_id = ''
          this.innerListCurItem.delivery_outlets_type = ''
          this.innerListCurItem.delivery_outlets_id   = ''
          this.innerListCurItem.delivery_outlets_name = ''
          this.innerListCurItem.delivery_region_id    = '';
          this.innerListCurItem.freight_amount = 0
          this.innerListCurItem.area_freight_amount = 0
          this.$message.warning("超区，不在配送范围内");
        }

        // 输入后 选择 好像地址不刷新 强刷
        this.$forceUpdate()

        // 供货网点
        if(!this.innerListCurItem.order_no){
          await this.autoFigureShop(area)
        }
        
        // this.computedPrice(this.innerListCurItem)
      }

    },

    handlerAddGoods (item) {
      // console.log('handlerAddGoods', this.innerListCurItem)
      this.innerListCurItem = item
      if (this.innerListCurItem.source_outlets_id) {
        this.isSelectGoods = true
      } else {
        this.$message.warning('请先选择负责网点后再添加商品！')
      }
    },
    // 添加商品
    addGoodsOk (rows) {
      rows.map(el => {
        if (!el.number) {
          el.number = 1
        }
        // 校正价格
        if(!el.goods_price) {
          el.goods_price = el.price
        }
        // 优先使用第三方价格
        let other_order_sku = this.other_order_goods_list.find(gd=>{
          return gd.goods_sku_number == el.goods_sku_number
        })
        if(other_order_sku){ 
          el.order_price = other_order_sku.single_order_price
        }else{
          el.order_price = el.price
        }

        // 如果添加的有蛋糕，则放入蛋糕列表一份
        if (el.good_type === 3) {
          el.diners_number = 0
          el.birthday_candle = null
          el.birthday_card = ''
          el.birthday_greeting_card = ''
        }
        if (el.stock < 0) {
          el.status = "无货"
        } else if (el.stock < el.number) {
          el.status = "库存不足"
        }
        this.innerListCurItem.pick_up_delivery_order.goods_list.push(el)
        // return el
      })

      this.updateCakeList(this.innerListCurItem)
      this.computedPrice(this.innerListCurItem)
    },

    updateCakeList (item) {
      this.innerListCurItem = item
      // console.log('updateCakeList', this.innerListCurItem)
      this.innerListCurItem.pick_up_delivery_order.cake_accessory_list.map(el=>{
        el.isHandled = false
      })
      const cake_accessory_list = this.innerListCurItem.pick_up_delivery_order.cake_accessory_list
      const cakes = []
      this.innerListCurItem.pick_up_delivery_order.goods_list.map(el => {
        if (el.goods_type == 3) {
          for (let i = 0; i < el.number; i++) {
            let cake_accessory = cake_accessory_list.find(ck => ck.order_goods_id == el.order_goods_id && !ck.isHandled)
            if(!cake_accessory){
              cake_accessory = cake_accessory_list.find(ck => ck.sku_id == el.sku_id && !ck.isHandled)
            }
            if(cake_accessory) cake_accessory.isHandled = true

            let cake = cake_accessory || {}
            let item = Object.assign({}, el, {
              diners_number:          cake.diners_number || el.diners_number, // 外卖平台 可能会有餐具
              birthday_candle:        cake.birthday_candle,
              birthday_card:          cake.birthday_card || el.birthday_card, // 外卖平台 可能会有祝福语
              birthday_greeting_card: cake.birthday_greeting_card,
              // number: 1,
            })
            cakes.push(item)
          }
        }
      })
      cakes.map(el=> el.isHandled = false)

      this.innerListCurItem.pick_up_delivery_order.cake_accessory_list = cakes
      this.$forceUpdate()
    },

    changeGoodNumber (val, item) {
      // console.log('changeGoodNumber', val)
      this.$forceUpdate()
      this.updateCakeList(item)
      this.computedPrice(item)
    },

    changeGoodOrderPrice(item) {
      // console.log('changeGoodOrderPrice', val)
      this.$forceUpdate()
      this.computedPrice(item)
    },

    // 删除
    handlerDeleteSku (index, item) {
      this.innerListCurItem = item
      this.innerListCurItem.pick_up_delivery_order.goods_list.splice(index, 1)
      this.updateCakeList(item)
      this.computedPrice(item)
    },

    // 保存全部订单
    async handlerSaveOrders() {
      if(this.order_list.some(el=> !el.source_outlets_id )){
        this.$message.info("请选择网点")
        return
      }

      let ordersTotalPrice = 0
      this.order_list.map(el=>{
        ordersTotalPrice += Number(el.freight_amount)
        
        el.pick_up_delivery_order.goods_list.map(e=>{
          ordersTotalPrice += Number(e.order_price)
        })
      })
      ordersTotalPrice = Math.round(ordersTotalPrice*100)/100

      if(ordersTotalPrice != this.otherTotalPrice) {
        let _this = this
        this.$confirm({
          // title: "温馨提示",
          content: '第三方订单的实收金额与发货订单总金额不相符，确认保存吗？',
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            _this.confirmEdit()
          }
        })
      }else{
        this.confirmEdit()
      }
    },
    
    //解散包裹
    deleteOrder(index){
      let _this = this
      let targetItem
      let targetIndex
      for(let i in this.order_list){
        const tOrder = this.order_list[i]
        // 跳过自己
        if(index == i) continue
        // 解散给一个有效订单
        if(tOrder.order_status >= 1 && tOrder.order_status < 30){
          targetItem = tOrder
          targetIndex = i
        }
      }

      const message = targetItem ? `解散包裹后，商品将被合并到订单${targetItem.order_no}中，确认”解散包裹“吗？` : "商品将被删除，确认”解散包裹“吗？"
      this.$confirm({
        // title: "温馨提示",
        content: message,
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          _this.confirmDeleteOrder(index, targetItem ? targetIndex : -1)
        }
      })
    },

    confirmDeleteOrder(index, targetIndex) {
      let item = this.order_list[index]
      let targetItem = targetIndex==-1 ? null : this.order_list[targetIndex]

      if(item.order_no){
        this.cancel_order_data.push({
          order_no: item.order_no
        })
      }
      item.pick_up_delivery_order.goods_list.map(el=>{
        if(el.order_goods_id) {
          //删除order_goods_id， 提交时设置为0，当成新加
          delete el.order_goods_id
        }
      })
      if(targetItem){
        targetItem.pick_up_delivery_order.goods_list = 
          targetItem.pick_up_delivery_order.goods_list.concat(item.pick_up_delivery_order.goods_list)
        this.updateCakeList(targetItem)
        this.computedPrice(targetItem)
      }
      
      this.order_list.splice(index, 1)
    },
    
    // 提交全部订单数据
    async confirmEdit() {
      let errTip = []

      let params = {
        other_order_id: this.other_order_id,
        open_time: this.open_time,
      }

      let create_order_data = []
      let update_order_data = []
      this.order_list.map((element, index)=>{
        if(!element.order_no) {//新建
          let create = {
            tmp_order_id: index+1,
            order_type: element.order_type,
            // outlets_id: element.source_outlets_id,
            place_order_name: element.place_order_name,
            place_order_phone: element.place_order_phone,
            freight_amount: element.freight_amount,
            customer_service_remarks: element.customer_service_remarks,
            private_remarks: element.private_remarks,
            pick_up_info: '',
            delivery_info: '',
            goods_sku_data: '',
            is_audit: element.isAudit ? 2 : 1,
          }
          if(element.goods_time.length < 2){
            errTip.push("自提时间/配送时间必填")
          }
          if(element.order_type == 1){
            let pick_date = element.goods_time.length==2 ? element.goods_time[0]: ""
            let pick_time = element.goods_time.length==2 ? element.goods_time[1]: ""
            create.pick_up_info = {
              pick_up_goods_time_start: pick_time ? (pick_date+" "+pick_time.split('-')[0]) : "",
              pick_up_goods_time_end:   pick_time ? (pick_date+" "+pick_time.split('-')[1]) : "",
              recipient_name: element.pick_up_delivery_order.pick_up_info.recipient_name,
              recipient_phone: element.pick_up_delivery_order.pick_up_info.recipient_phone,
            }
            create.outlets_id = element.pickup_shop_id
          }
          if(element.order_type == 2){
            let dlvr_date = element.goods_time.length==2 ? element.goods_time[0]: ""
            let dlvr_time = element.goods_time.length==2 ? element.goods_time[1]: ""
            create.delivery_info = {
              delivery_region_id: element.delivery_region_id,
              delivery_time_start: dlvr_time ? (dlvr_date+" "+dlvr_time.split('-')[0]) : "",
              delivery_time_end:   dlvr_time ? (dlvr_date+" "+dlvr_time.split('-')[1]) : "",
              consignee_lng: element.consignee_lng,
              consignee_lat: element.consignee_lat,
              consignee_name: element.pick_up_delivery_order.delivery_info.consignee_name,
              consignee_phone: element.pick_up_delivery_order.delivery_info.consignee_phone,
              consignee_address: element.consignee_address + (element.consignee_address_detail || ""),
              consignee_adcode: element.consignee_adcode,
            }
            create.outlets_id = element.source_outlets_id
          }

          let cakes = []
          let others = []
          element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          element.pick_up_delivery_order.goods_list.map(el => {
            if (el.goods_type == 3) {
              // let arr = element.pick_up_delivery_order.cake_accessory_list.filter(ck => ck.order_goods_id == el.order_goods_id)
              for (let i = 0; i < el.number; i++) {
                let cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.order_goods_id == el.order_goods_id && !ck.isHandled)
                if(!cake_accessory){
                  cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.sku_id == el.sku_id && !ck.isHandled)
                }
                if(cake_accessory) cake_accessory.isHandled = true

                let cake = cake_accessory || {}
                let item = Object.assign({}, el, {
                  diners_number:          cake.diners_number,
                  birthday_candle:        cake.birthday_candle,              
                  birthday_card:          cake.birthday_card,  
                  birthday_greeting_card: cake.birthday_greeting_card,
                })
                cakes.push(item)
              }
            }else{
              others.push({
                order_goods_id: el.order_goods_id||0,
                number: el.number,
                price:  el.order_price,
                sku_id: el.sku_id,
              })
            }
          })
          element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          create.goods_sku_data = cakes.map(el=>{
            let sku = {
              sku_id: el.sku_id,
              price: el.order_price,
              number: el.number,
              diners_number: el.diners_number,
              birthday_candle: el.birthday_candle,
              birthday_card: el.birthday_card,
              birthday_greeting_card: el.birthday_greeting_card,
            }
            return sku
          }).concat(others)

          create_order_data.push(create)
        }else if(element.order_status >= 1 && element.order_status < 30){ // 修改（订单没取消&没完成）
          let update = {
            tmp_order_id: index+1,
            order_no: element.order_no,
            order_type: element.order_type,
            place_order_name: element.place_order_name,
            place_order_phone: element.place_order_phone,
            freight_amount: element.freight_amount,
            customer_service_remarks: element.customer_service_remarks,
            private_remarks: element.private_remarks,
            pick_up_info: '',
            delivery_info: '',
            goods_sku_data: '',
            is_audit: element.isAudit ? 2 : 1,
          }
          if(element.order_type == 1){
            update.pick_up_info = {
              pick_up_goods_time_start: `${element.goods_time[0]} ${element.goods_time[1].split('-')[0]}`,
              pick_up_goods_time_end: `${element.goods_time[0]} ${element.goods_time[1].split('-')[1]}`,
              recipient_name: element.pick_up_delivery_order.pick_up_info.recipient_name,
              recipient_phone: element.pick_up_delivery_order.pick_up_info.recipient_phone,
            }
            update.outlets_id = element.pickup_shop_id
          }
          if(element.order_type == 2){
            update.delivery_info = {
              delivery_time_start: `${element.goods_time[0]} ${element.goods_time[1].split('-')[0]}`,
              delivery_time_end: `${element.goods_time[0]} ${element.goods_time[1].split('-')[1]}`,
              consignee_lng: element.consignee_lng,
              consignee_lat: element.consignee_lat,
              consignee_name: element.pick_up_delivery_order.delivery_info.consignee_name,
              consignee_phone: element.pick_up_delivery_order.delivery_info.consignee_phone,
              consignee_address: element.consignee_address,
              consignee_adcode: element.consignee_adcode,
            }
            update.outlets_id = element.delivery_outlets_id
          }

          let cakes = []
          let others = []
          element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          element.pick_up_delivery_order.goods_list.map(el => {
            if (el.goods_type == 3) {
              // let arr = element.pick_up_delivery_order.cake_accessory_list.filter(ck => ck.order_goods_id == el.order_goods_id)
              for (let i = 0; i < el.number; i++) {
                let cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.order_goods_id == el.order_goods_id && !ck.isHandled)
                if(!cake_accessory){
                  cake_accessory = element.pick_up_delivery_order.cake_accessory_list.find(ck => ck.sku_id == el.sku_id && !ck.isHandled)
                }
                if(cake_accessory) cake_accessory.isHandled = true
                let cake = cake_accessory || {}
                let item = Object.assign({}, el, {
                  diners_number:          cake.diners_number,    
                  birthday_candle:        cake.birthday_candle,              
                  birthday_card:          cake.birthday_card,  
                  birthday_greeting_card: cake.birthday_greeting_card,
                })
                cakes.push(item)
              }
            }else{
              others.push({
                order_goods_id: el.order_goods_id||0,
                number: el.number,
                price:  el.order_price,
                sku_id: el.sku_id,
              })
            }
          })
          element.pick_up_delivery_order.cake_accessory_list.map(el=>el.isHandled = false)
          update.goods_sku_data = cakes.map(el=>{
            let sku = {
              order_goods_id: el.order_goods_id||0,
              sku_id: el.sku_id,
              price: el.order_price,
              number: el.number,
              diners_number: el.diners_number,
              birthday_candle: el.birthday_candle,
              birthday_card: el.birthday_card,
              birthday_greeting_card: el.birthday_greeting_card,
            }
            return sku
          }).concat(others)

          update_order_data.push(update)
        }
      })

      if(this.cancel_order_data.length>0){//有删除的订单
        let curLength = this.order_list.length
        this.cancel_order_data.map((el, i)=>{
          el.tmp_order_id = Number(curLength) + (i+1)
        })
      }

      if(errTip.length){
        this.$message.info(errTip.shift())
        return
      }


      // console.log('pamams',{
      //   cancel_order_data: this.cancel_order_data,
      //   create_order_data: create_order_data,
      //   update_order_data: update_order_data,
      // })
      params.cancel_order_data = JSON.stringify(this.cancel_order_data)
      params.create_order_data = JSON.stringify(create_order_data)
      params.update_order_data = JSON.stringify(update_order_data)
      
      if(this.isLoading) return
      this.isLoading = true
      const { code, data } = await otherOrderEdit(params)
      this.isLoading = false

      if (code == 0) {
        
        if(data.submit_result == 'error') {
          // test
          // data.error_data.error_type
          // data.error_data.error_tmp_order_id
          // data.error_data.error_message
          // if(data.error_data.error_type == 4){ // 库存不足
          //   // data.error_data.goods_sku_list
          // }else if(data.error_data.error_type == 5){ // 部分商品不存在
          //   // data.error_data.goods_sku_list
          // }else{
          //   this.$message.error(data.error_data.error_message)
          // }
          this.$message.error(data.error_data.error_message)
        }
        if(!data.submit_result||data.submit_result == 'success') {
          this.$message.success("已保存")
          // this.$router.go(0)
          // this.initData()
          //提交成功，同步第三方平台
          if(this.checkedThirdSend) {
            await this.syncDelivery()
            this.initData()
          }else{
            this.initData()
          }
        }
      }
    },

    handlerCancel() {
      this.$confirm({
        // title: "温馨提示",
        content: (
          <div>
            <div>取消后，所有内部订单将取消，确认操作吗？</div>
            <div>（取消后，请移步第三方平台进行相应处理）</div>
          </div>
        ),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          console.log('handlerCancel ok')
          this.doCancel()
        }
      })
    },

    // handlerNotEidt() {
    //   this.$router.push({path: '/other-order'})
    // },

    async handlerProcessed() {
      const { code } = await otherOrderProcessed({
        other_order_id: this.other_order_id
      })
      if (code == 0) {
        this.$message.success("已处理")
        this.initData()
      }
    },

    handlerCopyContact(orderItem){
      if(orderItem.order_type == 1){
        orderItem.place_order_name  = orderItem.pick_up_delivery_order.pick_up_info.recipient_name
        orderItem.place_order_phone = orderItem.pick_up_delivery_order.pick_up_info.recipient_phone
      }
      if(orderItem.order_type == 2){
        orderItem.place_order_name  = orderItem.pick_up_delivery_order.delivery_info.consignee_name
        orderItem.place_order_phone = orderItem.pick_up_delivery_order.delivery_info.consignee_phone
      }
      this.$forceUpdate()
    },

    handlerCopyRemark(orderItem,from,to){
      if(from == "order"){
        if(to == "customer"){
          orderItem.customer_service_remarks = this.otherOrderData.other_order_remarks
        }
        if(to == "inner"){
          orderItem.private_remarks = this.otherOrderData.other_order_remarks
        }
      }
      if(from == "customer"){
        if(to == "customer"){
          orderItem.customer_service_remarks = this.otherOrderData.other_order_customer_service_remarks
        }
        if(to == "inner"){
          orderItem.private_remarks = this.otherOrderData.other_order_customer_service_remarks
        }
      }
    },

    getCakeSkuInfo(itemC, item){
      let sku
      if(itemC.order_goods_id){
        sku = item.pick_up_delivery_order.goods_list.find(el=>{
          return el.order_goods_id == itemC.order_goods_id
        })
      }else{
        sku = item.pick_up_delivery_order.goods_list.find(el=>{
          return el.goods_sku_number == itemC.goods_sku_number
        })
      }
      return sku || {}
    },

    onChangeTimePoint(){
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="less">
.has-update {
  background-color: #fff;
  border-color: #f5222d;
  .ant-select-selection {
    border-color: #f5222d;
  }
  .ant-cascader-input {
    border-color: #f5222d;
  }
  input{
    border-color: #f5222d;
  }
}
.dflex {
  display: flex;
  align-items: center;
  &.w-s{
    width: 360px;
  }
  &.w-m{
    width: 470px;
  }
  .label {
    padding: 0 10px;
    min-width: 90px;
  }
  .val {
    flex: 1;
  }
}
.not-form{
  display: flex;
  align-items: center;
  .label {
    padding: 0 10px 0 0;
    min-width: unset;
  }
}
.amount-pannel{
  width: 240px;
  // color: #000;
  .flex .flex{
    width: 200px;
  }
  .grey{
    // color: #aaa;
  }
}
.innerpartydiv{
  padding-top: 0;
}
.inner-order{
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  .order-title{
    background-color: #fafafa;
    border-bottom: 1px solid #d9d9d9;

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }
  .order-content {
    padding: 10px;
  }
}

</style>

<style>
.ant-row-flex .dflex:first-child .label:first-child,
.ant-row .dflex:first-child .label:first-child{
  padding-left: 0;
}
</style>
