
import { getGoodsListByOutletsId } from "@/api/order/create"

// sku码、网点id
export async function filterSkuList(sku_nums, outlets_id) {
  sku_nums = sku_nums || []
  if(!sku_nums.length) return []

  const { data, code } = await getGoodsListByOutletsId({
    outlets_id: outlets_id,
    goods_sku_number: sku_nums.join(","),
    page: 1,
    page_count: 1000,
  })
  if (code === 0) {
    let skuArr = []
    const datas = data.list
    datas.map((goods) => {
      if(goods.sku_data){
        goods.sku_data.map((item) => {
          let obj = goods
          obj.flavor_name = item.flavor_name
          if (item.specification_list.length > 0) {
            item.specification_list.map((el) => {
              obj = { ...obj, ...el }
              delete obj.sku_data
              skuArr.push(obj)
            })
          }
        })
      } 
    })
    return skuArr.filter(el=>{
      return sku_nums.includes(el.goods_sku_number)
    })
  }
  return []
}












