<template>
  <a-modal :visible="show"
           title="选择商品拆分发货"
           :afterClose="remove"
           width="800px"
           @cancel="remove"
           @ok="handleOk">

      <base-table class="mb-4" 
        :columnsData="column" 
        rowKey="row_id"
        :tableData="tableData" 
        :row-selection="rowSelection"
      >
      </base-table>
  </a-modal>

</template>

<script>
// import { 
//   formatGoodsType,
// } from '@/utils/type'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    goodsList: {
      type: Array,
      default: ()=>[]
    }
  },
  data () {
    return {
      selectRows: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            // this.rowSelection.selectedRowKeys.unshift(record.sku_id)
            this.rowSelection.selectedRowKeys.unshift(record.row_id)
            this.selectRows.unshift(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              // (el) => el != record.sku_id
              (el) => el != record.row_id
            )
            // this.selectRows = this.selectRows.filter(el => el.sku_id != record.sku_id)
            this.selectRows = this.selectRows.filter(el => el.row_id != record.row_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          // this.selectRows = selectedRows
          if(selected){
            // this.rowSelection.selectedRowKeys = selectedRows.map(el=>el.sku_id)
            this.rowSelection.selectedRowKeys = selectedRows.map(el=>el.row_id)
            this.selectRows = selectedRows
          }else{
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },
      },
      column: [
        // {
        //   title: "分类",
        //   dataIndex: "goods_type",
        //   align: "center",
        //   slots: {
        //     customRender: "type",
        //   },
        //   slotsType: "format",
        //   slotsFunc: (val) => formatGoodsType(val),
        // },
        {
          title: "SKU码",
          dataIndex: "goods_sku_number",
          align: "center",
          width: 90,
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          width: "40%",
        },

        {
          title: "口味",
          dataIndex: "flavor_name",
          align: "center",
          width: "30%",
        },
        {
          title: "尺寸",
          dataIndex: "specification_name",
          align: "center",
          width: "30%",
        },
      ],
      tableData: [],
    }
  },

  mounted() {
    this.initData()
  },

  methods: {
    initData(){
      let data = JSON.parse(JSON.stringify(this.goodsList))
      let i = 0
      this.tableData = data.map(el=>{
        el.row_id = el.sku_id+"-"+(el.keya||i++)
        return el
      })
      console.log('initdata', this.tableData)
    },
    remove () {
      this.$emit('update:show', false)
    },
    async handleOk () {
      if(this.rowSelection.selectedRowKeys.length == 0){
        this.$message.warning('请选择商品')
        return
      }
      if(this.rowSelection.selectedRowKeys.length == this.tableData.length) {
        this.$message.warning('不可以全选拆分')
        return
      }
      this.$emit('update:show', false)
      this.$emit('ok', this.selectRows)

    },
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>