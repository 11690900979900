<template>
  <a-modal :visible="show"
           title="第三方订单的流水记录"
           destroyOnClose
           width="900px"
           @cancel="$emit('update:show', false)"
           :footer="null">
    <!-- <a-tabs type="card" class="w-full" @change="handlerTypeChangeModal" :activeKey="activeKeyModal">
      <a-tab-pane key="1" tab="退费流水">
      </a-tab-pane>
      <a-tab-pane key="2" tab="补差流水">
      </a-tab-pane>
    </a-tabs> -->
    <base-table class="mb-4" 
      rowKey="flow_no"
      :columnsData="flowLogColumn" 
      :tableData="flowLogTableData" 
      :customHeight="300"
    >
    </base-table>
  </a-modal>
</template>

<script>
import {
  // getCakeOrderList, //test
  otherOrderGetFlowLog,
} from "@/api/customer"
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: [Number,String],
      default: ''
    }
  },
  data () {
    return {
      activeKeyModal: "1",
      flowLogColumn: [
        {
          title: "添加时间",
          dataIndex: "add_time",
          align: "center",
          width: 170,
        },
        {
          title: "流水时间",
          dataIndex: "flow_time",
          align: "center",
          width: 170,
        },
        {
          title: "类型",
          dataIndex: "flow_type",
          align: "center",
          width: 80,
          slots: {
            customRender: "flow_type",
          },
          slotsType: "format",
          slotsFunc: (val) => { 
            if(val == 1) return "订单支付"
            if(val == 2) return "退款"
            if(val == 3) return "补差"
            return val
          },
        },
        {
          title: "金额",
          dataIndex: "amount",
          align: "center",
          width: 80,
          slots: {
            customRender: "amount",
          },
          slotsType: "all",
          slotsFunc: (val) => { 
            if(val.flow_type == 1 || val.flow_type == 3) return "+"+val.amount
            if(val.flow_type == 2) return "-"+val.amount
            return val.amount
          },
        },
        {
          title: "备注",
          dataIndex: "remarks",
          align: "center",
          // width: 180,
        },
        {
          title: "添加人",
          dataIndex: "operator",
          align: "center",
          width: 100,
        },
      ],
      flowLogTableData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      let { data, code } = await otherOrderGetFlowLog({ 
        other_order_id: this.orderId, 
        // flow_type: this.activeKeyModal 
        flow_type: 0
      })

      if(code == 0){
        this.flowLogTableData = data.list.map(el=>{
          // el.sortTime = new Date(el.flow_time.replace(/-/g,"/")).getTime()
          el.sortTime = new Date(el.add_time.replace(/-/g,"/")).getTime()
          return el
        }).sort((a,b)=>{
          return a.sortTime - b.sortTime
        })
      }
    },
    handlerTypeChangeModal(e) {
      this.activeKeyModal = e
      this.initData()
    }
  }
}
</script>

<style>

</style>