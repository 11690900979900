<template>
  <a-modal
    :visible.sync="show"
    title="选择网点"
    destroyOnClose
    width="50%"
    @cancel="$emit('update:show', false)"
    :footer="null"
  >
    <!-- <div class="modal-search-bar">
      <a-form-model
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item prop="adcode" label="网点城市">
              <a-select v-model="adcode" style="width:140px;" @change="initData" placeholder="门店城市">
                <a-select-option
                  v-for="(item, index) in cityList"
                  :key="index"
                  :value="item.adcode"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item prop="outlets_name" label="网点名称">
              <a-input allowClear v-model="searchForm.outlets_name" placeholder="网点名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item style="margin-left:20px;" :labelCol="{ span: 0 }">
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div> -->
    <base-table
      :rowClassName="getRowClass"
      :customHeight="300"
      :columnsData="columns"
      rowKey="outlets_id"
      :tableData="tableData"
      :total="total"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #operation="{ record }">
        <a-button type="link" @click="handlerSelect(record)">选择</a-button>
      </template>
    </base-table>
  </a-modal>
</template>

<script>
// import { getCityList } from "@/api/outlets"
import { getDeliveryOutletsList, getPickUpOutletsList } from "@/api/order/create"
import { getDistance, IsPtInPoly } from "@/utils/tMap.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: [String, Number],
    },
    formData: {
      type: Object,
      default: () => { }
    },
    selectKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      adcode: '',
      searchForm: {
        outlets_name: "",
        page: 1,
        page_count: 10,
      },
      allColumns: [
        {
          title: "网点名称",
          dataIndex: "outlets_name",
          align: "center",
          width: 140,
        },
        {
          title: "联系电话",
          dataIndex: "tel",
          align: "center",
          width: 130,
        },
        {
          title: "网点类型",
          dataIndex: "outlets_type",
          align: "center",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => this.formatType(val),
        },
        {
          title: "覆盖范围",
          dataIndex: "cover_name",
          align: "center",
        },
        {
          title: "有库存",
          dataIndex: "have_stock",
          align: "center",
        },
        {
          title: "距离",
          dataIndex: "distance",
          align: "center",
          slots: {
            customRender: "distance",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val+" km" },
        },
        {
          title: "运费",
          dataIndex: "freight",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          slots: {
            customRender: "operation",
          },
        },
      ],
      columns: [],
      cityList: [],
      tableData: [],
      baseList: [],
      total: 0,
    }
  },
  mounted() {
    console.log("mounted formData", this.formData)

    let adcode = this.formData.city && this.formData.city.value
    if(!adcode){
      adcode = this.formData.consignee_adcode
    }
    this.adcode = adcode

    this.initData()
  },
  methods: {
    formatType(val) {
      return val === 1 ? '门店' : '配送站'
    },

    async initData() {
      let res = ''
      // 如果是配送单
      if (this.formData.order_type === 2) {
        if(this.formData.consignee_lat){
          this.columns = this.allColumns.filter(el=>!['have_stock'].includes(el.dataIndex))
        }else{
          this.columns = this.allColumns.filter(el=>!['distance','have_stock'].includes(el.dataIndex))
        }
        res = await getDeliveryOutletsList({ adcode: this.adcode })
      } else {
        if(this.formData.consignee_lat){
          this.columns = this.allColumns
        }else{
          this.columns = this.allColumns.filter(el=>!['distance'].includes(el.dataIndex))
        }
        await getPickUpOutletsList(this.searchForm)
      }
      // test 这块有问题
      const areaList = this._getDeliveryArea(res.data.list, {
        lat: this.formData.consignee_lat,
        lng: this.formData.consignee_lng
      })

      this.tableData = areaList.map(item => {
        item.cover_name = item.is_cover === 2 ? '是' : '否'
        item.have_stock = item.have_stock === 1 ? '否' : '是'

        return item
      })
    },
    // 保存
    handlerSearch() {
      this.tableData = this.baseList.filter(item => item.outlets_name.includes(this.searchForm.outlets_name))
    },
    handlerSelect(row) {

      const areas = this._getDeliveryAreaPOI(row, {
        lat: this.formData.consignee_lat,
        lng: this.formData.consignee_lng
      })
      if (areas) {
        // this.formData.outlets_id = row.outlets_id
        // this.formData.outlets_name = row.outlets_name
        // this.formData.outlets_adcode = this.adcode
        // this.formData.delivery_region_id = areas.delivery_region_id
        // this.formData.freight_amount = areas.freight
        this.$emit("ok", {
          outlets_type: row.outlets_type,
          outlets_id: row.outlets_id,
          outlets_name: row.outlets_name,
          outlets_adcode: row.adcode,
          delivery_region_id: row.delivery_region_id,
          freight: row.freight,
        })
        this.$emit("update:show", false)
      } else {
        this.$confirm({
          title: "温馨提示",
          content: '收货地址不在所选网点的配送范围内，确认选择？',
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            // this.formData.outlets_id = row.outlets_id
            // this.formData.outlets_name = row.outlets_name
            // this.formData.outlets_adcode = this.adcode
            // this.formData.delivery_region_id = ''
            this.$emit("ok", {
              outlets_type: row.outlets_type,
              outlets_id: row.outlets_id,
              outlets_name: row.outlets_name,
              outlets_adcode: row.adcode,
              delivery_region_id: row.delivery_region_id,
              freight: 0,
            })
            this.$emit("update:show", false)
          }
        })
      }

    },

    getRowClass(record) {
      if (record.is_cover === 2) {
        return 'cover-status'
      } else {
        return 'cover-disable-status'
      }
    },

    // 多网点（含配送范围） --> 能配送的网点（含配送范围）
    _getDeliveryArea(outletsList, poi) {
      let list = (outletsList || []).filter(el=>{
        return el.delivery_region_list && el.delivery_region_list.length > 0
      }).map(outlets=>{
        if(poi.lat){
          outlets.distance = getDistance(
            poi.lat, 
            poi.lng,
            outlets.outlets_lat_lng.split(' ')[1], 
            outlets.outlets_lat_lng.split(' ')[0]
          ).toFixed(2)
        }

        let areaArr = outlets.delivery_region_list.sort((a, b) => {
          return a.freight - b.freight
        })
        // 默认第一个配送范围
        outlets.is_cover = 1
        outlets.delivery_region_id = areaArr[0].delivery_region_id
        outlets.freight = 0
        // 计算哪个配送范围可以覆盖
        for(let i=0; i<areaArr.length; i++){
          let region = areaArr[i].region;
          let tmp = region.split(",");
          let pointList = tmp.map(el => {
            let t = el.split(' ');
            return {
              latitude: Number(t[1]),
              longitude: Number(t[0])
            }
          })
          if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
            outlets.is_cover = 2
            outlets.delivery_region_id = areaArr[i].delivery_region_id
            outlets.freight = areaArr[i].freight
            break
          }
        }
        return outlets
      })

      list = list.sort((a, b) => {
        if(a.is_cover == b.is_cover){
          if(a.distance){
            return a.distance - b.distance
          }
          return 0
        }
        return b.is_cover - a.is_cover
      });

      return list;
    },

    // // 多网点（含配送范围） --> 能配送的网点（含配送范围）
    _getDeliveryAreaPOI(outlets, poi) {
      let areaList = outlets.delivery_region_list;

      for (let i = 0; i < areaList.length; i++) {
        let region = areaList[i].region;
        let tmp = region.split(",");
        let pointList = tmp.map(el => {
          let t = el.split(' ');
          return {
            latitude: Number(t[1]),
            longitude: Number(t[0])
          }
        })
        if (IsPtInPoly(poi.lat, poi.lng, pointList)) {
          return areaList[i]
        }
      }
      return null;
    },
  },
}
</script>

<style lang="less">
.ant-modal-body {
  position: relative;
  // min-height: 300px;
  margin-bottom: 10px;
}
.cover-disable-status {
  // background: rgb(205, 205, 205);
  // opacity: 0.4;
  color: #ccc;
  // &:hover {
  //   background: rgb(205, 205, 205);
  //   opacity: 0.8;
  // }
}
  
</style>