<template>
  <div>
    <a-modal class="detail-con"
      :visible.sync="show"
      width="90%"
      title="订单详情"
      :footer="null"
      @cancel="$emit('update:show', false)"
    >
      <div class="block-con">
        <!-- <h3 class="mt-3 title-bg">订单信息</h3> -->
        <a-form-model v-if="orderData"
          layout="horizontal"
          ref="formRef"
        >
          <div class="flex justify-between mb-2">
            <div class="info-con">
              <div class="item">
                <h3 class="label">{{ formatExpressOrderSource(orderData.order_source) }}订单号</h3>
                {{ orderData.order_no }}
              </div>
            </div>
            <div class="info-con">
              <div class="item">
                <span class="label">商品原价</span>{{ orderData.order_goods_amount }}
              </div>
              <span class="item">-</span>
              <div class="item">
                <span class="label">优惠</span>{{ orderData.order_offers_amount }}
              </div>
              <span class="item">+</span>
              <div class="item">
                <span class="label">运费</span>{{ orderData.order_freight_amount }}
              </div>
              <span class="item">=</span>
              <h3 class="item">
                <span class="label">订单金额</span>
                {{ orderData.order_amount }}
              </h3>
            </div>
          </div>
          <a-row>
              <base-table class="mb-2"
                    :columnsData="columns_goods"
                    :tableData="orderData.order_goods_list"
              ></base-table>
          </a-row>
        </a-form-model>
      </div>

      <div v-if="waybillList && waybillList.length" class="block-con">
        <h3 class="mt-3 title-bg">运单列表</h3>
        <base-table class="mb-2"
            :columnsData="columns_waybill"
            :tableData="waybillList"
        >
          <!-- <template #goods="{text}">
            <a-link style="color:#1890ff;cursor:pointer;" @click="handlerWaybillPage(text)">{{text}}</a-link>
          </template> -->
          <!-- <template #waybill_no="{text}">
            <a-link style="color:#1890ff;cursor:pointer;" @click="handlerWaybillPage(text)">{{text}}</a-link>
          </template> -->
        </base-table>
      </div>

      <div v-if="refundList && refundList.length" class="block-con">
        <h3 class="mt-3 title-bg">退款核算表</h3>
        <base-table class="mb-2"
            :columnsData="columns_refund"
            :tableData="refundList"
        >
          <template #operation="{record}">
            <a-button v-if="record.refund_compute_error == 2 || record.refund_compute_status == 1" type="link" @click="handlerEditRefund(record)">拆解</a-button>
            <a-button v-else type="link" @click="handlerEditRefund(record)">修改</a-button>
          </template>
        </base-table>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { 
  formatExpressOrderSource 
} from "@/utils/type.js"
import { 
  orderDetail
} from "@/api/express.js"
import { rowSpan } from "@/utils/index"

export default {
  components: {

  },
  props: {
    show: {
      type: Boolean
    },
    order_no: {
      type: [String, Number]
    }
  },
  data() {
    return {
      formatExpressOrderSource,

      orderData: null,

      columns_goods: [
        {
          title: "商品编码",
          dataIndex: "goods_sku_number",
          align: "center",
          width: 90,
        },
        {
          title: "商品名",
          dataIndex: "goods_name",
          align: "center",
          width: "50%",
        },
        {
          title: "口味规格",
          dataIndex: "flavor_name",
          align: "center",
          width: "35%",
          slots: {
            customRender: "flavor_name",
          },
          slotsType: "all",
          slotsFunc: (row) => { 
            return row.flavor_name + (row.specification_name ? (" "+row.specification_name) : "")
          },
        },
        {
          title: "购买数量",
          dataIndex: "number",
          align: "center",
          width: 80,
        },
        {
          title: "原价",
          dataIndex: "original_price",
          align: "center",
          width: 80,
        },
        {
          title: "优惠后",
          dataIndex: "goods_price",
          align: "center",
          width: 80,
        },
      ],

      waybillList: [],
      columns_waybill: [
        {
          title: "工厂",
          dataIndex: "warehouse_name",
          align: "center",
          width: 100,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.waybill_noRowSpan, 
              },
            }
          },
        },
        {
          title: "运单号",
          dataIndex: "waybill_no",
          align: "center",
          // width: 100,
          // slots: {
          //   customRender: "waybill_no",
          // },
          customRender: (text, row) => {
            return {
              children: (
                <a-link style="color:#1890ff;cursor:pointer;" 
                  onClick={() => { this.handlerWaybillPage(text) }}>{{text}}</a-link>
              ),
              attrs: {
                rowSpan: row.waybill_noRowSpan, 
              },
            }
          },
        },
        {
          title: "发货时间",
          dataIndex: "deliver_time",
          align: "center",
          // width: 100,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.waybill_noRowSpan, 
              },
            }
          },
        },
        {
          title: "运单状态",
          dataIndex: "deliver_status",
          align: "center",
          width: 90,
          customRender: (text, row) => {
            if(row.receipt_status == 2){
              return {
                children: (
                  <span>已签收</span>
                ),
                attrs: {
                  rowSpan: row.waybill_noRowSpan,
                },
              }
            }else if(row.deliver_status == 2){
              return {
                children: (
                  <span>已发货</span>
                ),
                attrs: {
                  rowSpan: row.waybill_noRowSpan,
                },
              }
            }else{
              return {
                children: (
                  <span>未发货</span>
                ),
                attrs: {
                  rowSpan: row.waybill_noRowSpan,
                },
              }
            }
          },
        },
        {
          title: "运单金额",
          dataIndex: "waybill_amount",
          align: "center",
          width: 100,
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.waybill_noRowSpan, 
              },
            }
          },
        },
        {
          title: "多订单合并发货",
          dataIndex: "is_orders",
          align: "center",
          width: 120,
          customRender: (text, row) => {
            if(row.is_orders == 2){
              return {
                children: (
                  <span>是</span>
                ),
                attrs: {
                  rowSpan: row.waybill_noRowSpan,
                },
              }
            }else{
              return {
                children: (
                  <span></span>
                ),
                attrs: {
                  rowSpan: row.waybill_noRowSpan,
                },
              }
            }
          },
        },
        {
          title: "商品组合码",
          dataIndex: "goods_suite_no",
          align: "center",
          width: 100,
        },
        {
          title: "SKU码",
          dataIndex: "goods_sku_number",
          align: "center",
          width: 90,
        },
        {
          title: "数量",
          dataIndex: "goods_number",
          align: "center",
          width: 90,
        }
      ],

      refundList: [],
      columns_refund: [
        {
          title: "退款编号",
          dataIndex: "other_refund_id",
          align: "center",
          width: 190,
        },
        {
          title: "退款时间",
          dataIndex: "refund_time",
          align: "center",
          width: 180,
        },
        {
          title: "退款商品",
          dataIndex: "refund_goods",
          align: "center",
        },
        {
          title: "退款金额",
          dataIndex: "refund_amount",
          align: "center",
          width: 80,
        },
        {
          title: "工厂退款",
          dataIndex: "warehouse_bear",
          align: "center",
          width: 140,
          slots: {
            customRender: "warehouse_bear",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val || "——" },
        },
        // {
        //   title: "结算状态",
        //   dataIndex: "refund_compute_error",
        //   align: "center",
        //   width: 100,
        //   slots: {
        //     customRender: "refund_compute_error",
        //   },
        //   slotsType: "all",
        //   slotsFunc: (row) => { 
        //     if(row.refund_compute_error==2) return "有错误"
        //     return row.refund_compute_status == 2 ? "" : "未计算" 
        //   },
        // },
        {
          title: "存在问题",
          dataIndex: "refund_compute_error",
          align: "center",
          width: 100,
          slots: {
            customRender: "refund_compute_error",
          },
          slotsType: "all",
          slotsFunc: (row) => { 
            return row.refund_compute_error==2 ? "是" : ""
          },
        },
        {
          title: "处理人",
          dataIndex: "refund_compute_error_update_user",
          align: "center",
          width: 100,
          slots: {
            customRender: "refund_compute_error_update_user",
          },
          slotsType: "format",
          slotsFunc: (val) => { return val || "" },
        },
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {

    async initData() {
      const { data, code } = await orderDetail({
        order_no: this.order_no
      })
      if (code == 0) {
        this.orderData = data

        let list = []
        data.waybill_list = data.waybill_list || []
        data.waybill_list.map(el=>{
          // 容错：如果运单的商品为空
          if(!el.goods_list || !el.goods_list.length){
            el.goods_list = [{
              goods_suite_no: "",
              goods_sku_number: "",
              goods_number: "",
            }]
          }
          el.goods_list.sort((a,b)=>{
            const strA = a.goods_suite_no+" "+a.goods_sku_number
            const strB = b.goods_suite_no+" "+b.goods_sku_number
            return strA.localeCompare(strB)
          })
          el.goods_list.map(gd=>{
            list.push(Object.assign({},gd,el))
          })
        })
        list = rowSpan(list, "waybill_no")

        this.waybillList = list
        this.refundList = data.order_refund_flow_list
      }
    },

    handlerEditWaybill(row){
      console.log("handlerEditWaybill", row)
    },
    handlerWaybillPage(waybill_no){
      const newPage = this.$router.resolve({
        name: "express-waybill",
        query: { 
          waybill_no: waybill_no,
        },
      });
      window.open(newPage.href, "_blank");
    },
    handlerEditRefund(row){
      console.log("handlerEditRefund", row)
    },
  },
}
</script>

<style lang="less" scoped>
.detail-con{
  /deep/.ant-modal{
    max-width: 1200px;
  }
}
.block-con .title-bg{
  //margin-bottom: 0;
}
.info-con{
  .item{
    // min-width: 200px;
    display: inline-block;
    margin-right: 10px;
    .label{
      display: inline-block;
      // width: 70px;
    }
  }
}
</style>
